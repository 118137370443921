import getCounterData from '../../api/Backend/Counter';

import {COUNTER_DATA_SENT, COUNTER_SENDING_DATA, COUNTER_SEND_FAILED} from './CounterReducer';

const COUNTER_CODE_ERROR = 400;
const COUNTER_CODE_SUCCESS = 200;

export default data => async dispatch => {
  dispatch({
    type: COUNTER_SENDING_DATA,
  });

  try {
    const response = await getCounterData(data);

    if (response.status !== COUNTER_CODE_ERROR) {
      dispatch({
        type: COUNTER_DATA_SENT,
        responseCode: COUNTER_CODE_SUCCESS,
        data: response.data.data.data,
      });

      return true;
    }
  } catch (e) {} // eslint-disable-line no-empty

  dispatch({type: COUNTER_SEND_FAILED, responseCode: COUNTER_CODE_ERROR});

  return false;
};

export {COUNTER_CODE_ERROR, COUNTER_CODE_SUCCESS};
