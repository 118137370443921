import React, {useState} from 'react';
import {Spring} from 'react-spring/renderprops.cjs.js';
import VisibilitySensor from 'react-visibility-sensor';

import isSafari from './../../_util/isSafari';
import isIPhoneOrIPod from './../../_util/isIPhoneOrIPod';
import isSSR from './../../config/isSSR';

const ScrollAnimation = ({children}) => {
  const withoutVisibilitySensor = isSSR() || isSafari() || isIPhoneOrIPod();
  const [visible, setVisible] = useState(withoutVisibilitySensor);

  const content = (
    <Spring to={{opacity: visible ? 1 : 0, transform: visible ? 'translateY(0)' : 'translateY(100px)'}}>
      {props => <div style={props}>{children}</div>}
    </Spring>
  );

  return withoutVisibilitySensor ? (
    content
  ) : (
    <VisibilitySensor active={!visible} onChange={isVisible => setVisible(isVisible)} partialVisibility>
      {content}
    </VisibilitySensor>
  );
};

export default React.memo(ScrollAnimation);
