import React from 'react';

import CmsLink from './../../../components/CmsLink/CmsLink';
import ContactComponent, {ContactButton} from './../../../components/Contact/Contact';

import ContactForm from './ContactForm';

const shouldShowCta = cta =>
  Boolean(
    cta &&
      !cta.disabled &&
      ((cta.hubspot && cta.hubspot.portalId && cta.hubspot.formId) ||
        (cta.title && cta.cta && cta.cta.text && cta.cta.url)),
  );

export const ContactContent = ({cta}) =>
  cta.hubspot && cta.hubspot.portalId && cta.hubspot.formId ? (
    <ContactForm formInfo={cta.hubspot} />
  ) : (
    <ContactButton>
      <CmsLink slug={cta.cta.url}>{cta.cta.text}</CmsLink>
    </ContactButton>
  );

const Contact = ({cta}) =>
  shouldShowCta(cta) ? (
    <ContactComponent title={cta.title}>
      <ContactContent cta={cta} />
    </ContactComponent>
  ) : null;

export default Contact;
