import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ScrollAnimation from './../../_behaviours/ScrollAnimation';
import ConditionalWrap from './../../_util/ConditionalWrap';

import './FullRow.css';

const FullRow = ({children, inverted, className, scrollAnimation, tag: FullRowTag, ...rest}) => (
  <ConditionalWrap condition={scrollAnimation} wrap={content => <ScrollAnimation>{content}</ScrollAnimation>}>
    <FullRowTag className={classNames('full-row', className, {'full-row--inverted': inverted})} {...rest}>
      {children}
    </FullRowTag>
  </ConditionalWrap>
);

FullRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  inverted: PropTypes.bool,
  scrollAnimation: PropTypes.bool,
  tag: PropTypes.string,
};

FullRow.defaultProps = {
  inverted: false,
  scrollAnimation: true,
  tag: 'div',
};

export default React.memo(FullRow);
