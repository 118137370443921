import React from 'react';

import ContentWithSidebar from '../../layouts/ContentWithSidebar/ContentWithSidebar';
import Typography, {VARIANT} from '../../atoms/Typography/Typography';
import CardImage from '../CardImage/CardImage';
import {RATIO} from '../../atoms/Picture/Picture';
import InlineButton from '../../atoms/InlineButton/InlineButton';
import Column from '../../layouts/Column/Column';

import './DoNotMiss.css';

const SectionTitle = ({title}) => (
  <ContentWithSidebar>
    <Column className="donotmiss__title">
      <Typography variant={VARIANT.H1}>{title}</Typography>
    </Column>
  </ContentWithSidebar>
);

const Cta = ({title}) => (
  <InlineButton withHover={true}>
    <Typography variant={VARIANT.BODY}>→</Typography> {title}
  </InlineButton>
);

const DoNotMiss = ({title, repeater: items}) => (
  <>
    <SectionTitle title={title} />
    {items.map(item => (
      <ContentWithSidebar
        className="donotmiss__item"
        left={
          <Column className="donotmiss__label">
            <div className="typography typography--body" dangerouslySetInnerHTML={{__html: item.label}}></div>
          </Column>
        }
      >
        <Column className="donotmiss__content">
          <a href={item.ctaUrl} target={item.ctaTarget}>
            <CardImage
              footer={
                <a href={item.ctaUrl} target={item.ctaTarget}>
                  <Cta title={item.ctaText} />
                </a>
              }
              horizontal={true}
              image={item.image}
              ratio={RATIO.R_16_9}
              title={<Typography variant={VARIANT.H3}>{item.title}</Typography>}
              withHover
            />
          </a>
        </Column>
      </ContentWithSidebar>
    ))}
  </>
);
export default DoNotMiss;
