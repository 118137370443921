import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import caseStudyPreviewPropType from './../../_propTypes/caseStudyPreviewPropType';

import InlineButton from './../../atoms/InlineButton/InlineButton';
import {RATIO} from '../../atoms/Picture/Picture';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import CardImage from './../CardImage/CardImage';

const CaseStudyPreview = ({caseStudy, renderContainer}) => (
  <article className="case-study-preview">
    <CardImage
      footer={
        <InlineButton>
          <FormattedMessage id="case_study_preview.read" />
        </InlineButton>
      }
      image={caseStudy.image}
      ratio={RATIO.R_16_9}
      renderContainer={renderContainer}
      title={<Typography variant={VARIANT.H2}>{caseStudy.title}</Typography>}
      withHover
    />
  </article>
);

CaseStudyPreview.propTypes = {
  caseStudy: caseStudyPreviewPropType.isRequired,
  renderContainer: PropTypes.func,
};

CaseStudyPreview.defaultProps = {
  renderContainer: children => children,
};

export default React.memo(CaseStudyPreview);
