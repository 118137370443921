import React from 'react';
import Lottie from 'react-lottie';
import * as animationCurvesData from './animation-curves.json';
import * as animationSpikeData from './animation-spike.json';

import './Loader.css';

const defaultOptions = animationData => ({
  loop: true,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
});

const Loader = () => {
  const animationData = Math.round(Math.random()) ? animationCurvesData : animationSpikeData;

  return (
    <div className="loader">
      <Lottie className="loader" options={defaultOptions(animationData)} />
    </div>
  );
};

export default React.memo(Loader);
