import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import isSSR from './../../../config/isSSR';

import './Picture.css';

const RATIO = {
  R_9_16: '9-16',
  R_3_4: '3-4',
  R_16_9: '16-9',
  R_3_2: '3-2',
  R_4_3: '4-3',
};

const imageIsLoaded = image => image.complete && image.naturalHeight !== 0;

class Picture extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mounted: false,
      loaded: false,
    };

    this.onLoaded = this.onLoaded.bind(this);
  }

  onLoaded() {
    this.setState({loaded: true});

    if (!isSSR() && window.objectFitPolyfill) {
      window.objectFitPolyfill();
    }
  }

  componentDidMount() {
    /* eslint-disable react/no-did-mount-set-state*/
    this.setState({mounted: !imageIsLoaded(this.image)});
    /* eslint-enable react/no-did-mount-set-state*/
  }

  render() {
    const {loaded, mounted} = this.state;
    const {
      className,
      pictureClassName,
      imageClassName,
      alt,
      src,
      srcSet,
      ratio,
      cover,
      contain,
      dataIndex,
      renderContainer,
      style,
      thumbnail,
      tag: PictureTag,
    } = this.props;
    const dataAttributes = {};
    const imgStyle = {};

    if (cover || contain) {
      Object.assign(dataAttributes, {
        'data-object-fit': cover ? 'cover' : 'contain',
      });

      Object.assign(imgStyle, {
        objectFit: cover ? 'cover' : 'contain',
      });
    }

    return (
      <PictureTag
        className={classNames(
          'picture',
          className,
          {
            'picture--mounted': mounted,
            'picture--loaded': loaded,
          },
          ratio ? `picture--ratio-${ratio}` : '',
        )}
        style={style}
      >
        {thumbnail && (
          <div className="picture__preload">
            {renderContainer(
              <img
                alt={alt}
                className={classNames('picture__image', imageClassName)}
                src={thumbnail}
                style={imgStyle}
                {...dataAttributes}
              />,
            )}
          </div>
        )}
        {renderContainer(
          <picture className={classNames('picture__picture', pictureClassName)}>
            {srcSet &&
              srcSet.map((srcSetObj, index) =>
                srcSetObj.src && srcSetObj.maxWidth ? (
                  <source key={index} media={`(max-width: ${srcSetObj.maxWidth}px)`} srcSet={srcSetObj.src} />
                ) : null,
              )}
            <img
              alt={alt}
              className={classNames('picture__image', imageClassName)}
              data-index={dataIndex}
              onLoad={this.onLoaded}
              ref={ref => {
                this.image = ref;
              }}
              src={src}
              style={imgStyle}
              {...dataAttributes}
            />
          </picture>,
        )}
      </PictureTag>
    );
  }
}

Picture.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  contain: PropTypes.bool,
  cover: PropTypes.bool,
  dataIndex: PropTypes.number,
  imageClassName: PropTypes.string,
  pictureClassName: PropTypes.string,
  ratio: PropTypes.oneOf(Object.values(RATIO)),
  renderContainer: PropTypes.func,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      maxWidth: PropTypes.number,
    }),
  ),
  style: PropTypes.object,
  tag: PropTypes.string,
  thumbnail: PropTypes.string,
};

Picture.defaultProps = {
  alt: '',
  renderContainer: children => children,
  tag: 'div',
};

export {RATIO};

export default React.memo(Picture);
