import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ScrollAnimation from './../../_behaviours/ScrollAnimation';
import ConditionalWrap from './../../_util/ConditionalWrap';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import Row from './../../layouts/Row/Row';
import Column from './../../layouts/Column/Column';
import ContentWithSidebar from './../../layouts/ContentWithSidebar/ContentWithSidebar';

import SectionTitle from './SectionTitle/SectionTitle';

import './Section.css';

const Section = ({
  children,
  className,
  contentColumnTag: ContentColumnTag,
  contentTag: ContentTag,
  label,
  scrollAnimation,
  title,
  titleParams,
  titleTag,
  titleVariant,
  ...rest
}) => {
  const TitleComponent = () => (
    <Row className="section__header" scrollAnimation={false}>
      <Column>
        <SectionTitle tag={titleTag} variant={titleVariant} {...titleParams}>
          {title}
        </SectionTitle>
      </Column>
    </Row>
  );

  return (
    <ConditionalWrap condition={scrollAnimation} wrap={content => <ScrollAnimation>{content}</ScrollAnimation>}>
      <section className={classNames('section', className)} {...rest}>
        {title &&
          (!label ? (
            <TitleComponent />
          ) : (
            <ContentWithSidebar
              className="section__content-with-sidebar"
              left={
                label && (
                  <Column>
                    <Typography tag="div" variant={VARIANT.BIG_BODY}>
                      {label}
                    </Typography>
                  </Column>
                )
              }
            >
              <TitleComponent />
            </ContentWithSidebar>
          ))}
        <ContentTag scrollAnimation={false}>
          <ContentColumnTag className="section__content">{children}</ContentColumnTag>
        </ContentTag>
      </section>
    </ConditionalWrap>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentColumnTag: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contentTag: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  scrollAnimation: PropTypes.bool,
  title: PropTypes.node,
  titleParams: PropTypes.object,
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleVariant: PropTypes.oneOf(Object.values(VARIANT)),
};

Section.defaultProps = {
  contentColumnTag: Column,
  contentTag: Row,
  scrollAnimation: true,
  titleParams: {},
};

export default React.memo(Section);
