import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import InlineButton from './../../atoms/InlineButton/InlineButton';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import Content from './../Content/Content';
import Column from './../../layouts/Column/Column';
import ContentWithSidebar from './../../layouts/ContentWithSidebar/ContentWithSidebar';

import './Subtitle.css';

const shouldShowCta = cta => cta && cta.text && cta.url;

const SubtitleAside = ({aside}) =>
  aside && aside.length > 0 ? (
    <Column className="subtitle__aside" scrollAnimation={false}>
      {aside.map(({text, title}, index) => (
        <>
          <Typography className="subtitle__aside-title" key={index}>
            {title}
          </Typography>
          <Typography className="subtitle__aside-text" variant={VARIANT.SMALL_BODY}>
            {text}
          </Typography>
        </>
      ))}
    </Column>
  ) : null;

const Subtitle = ({aside, cta, id, renderContainer, subtitle, withSeparator}) => (
  <ContentWithSidebar
    className={classNames('subtitle', {'subtitle--with-separator': withSeparator})}
    id={id ? id : null}
    left={<SubtitleAside aside={aside} />}
  >
    <Column className="subtitle__row">
      <Content raw tag="h2" variant={VARIANT.H2}>
        {subtitle}
      </Content>

      {shouldShowCta(cta) && (
        <InlineButton className="subtitle__cta">
          {renderContainer(cta.text, {rel: cta.relAttribute, target: cta.target}, cta.url)}
        </InlineButton>
      )}
    </Column>
  </ContentWithSidebar>
);

Subtitle.propTypes = {
  cta: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  id: PropTypes.string,
  renderContainer: PropTypes.func,
  subtitle: PropTypes.string,
  withSeparator: PropTypes.bool,
};

Subtitle.defaultProps = {
  renderContainer: children => children,
  withSeparator: true,
};

export default Subtitle;
