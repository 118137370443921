import PropTypes from 'prop-types';
import React from 'react';

import ExternalLink from '../../atoms/ExternalLink/ExternalLink';
import InlineButton from '../../atoms/InlineButton/InlineButton';
import Typography, {VARIANT} from '../../atoms/Typography/Typography';

import Column from '../../layouts/Column/Column';
import Row from '../../layouts/Row/Row';

import './JoinWeShare.css';

const JoinWeShare = ({social, id}) => (
  <section className="join-we-share" id={id}>
    <Row>
      <Column>
        <Typography className="join-we-share__title" variant={VARIANT.BODY}>
          {social.text}
        </Typography>
        <ul className="social-links">
          {social.share.map((link, index) => (
            <li className="social-links__item" key={index}>
              <ExternalLink aria-label={link.type} className="social-links__link" href={link.url}>
                <InlineButton variant={VARIANT.BODY}>{link.type}</InlineButton>
              </ExternalLink>
            </li>
          ))}
        </ul>
      </Column>
    </Row>
  </section>
);

JoinWeShare.propTypes = {
  id: PropTypes.any,
  social: PropTypes.shape({
    share: PropTypes.shape({
      map: PropTypes.func,
    }),
    text: PropTypes.node,
  }),
};

export default JoinWeShare;
