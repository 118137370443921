import React from 'react';
import PropTypes from 'prop-types';

import responsiveImagePropType from './../../_propTypes/responsiveImagePropType';

import Picture from './../../atoms/Picture/Picture';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import InlineButton from './../../atoms/InlineButton/InlineButton';

import Content from './../Content/Content';

import FullRow from './../../layouts/FullRow/FullRow';

import './ImageContent.css';

const ImageContent = ({image, cta, content, renderContainer, title}) => (
  <FullRow className="image-content">
    <Picture
      alt={image.alt}
      className="image-content__wrapper"
      imageClassName="image-content__image"
      pictureClassName="image-content__picture"
      src={image.src.medium || image.src.original}
      srcSet={[
        {
          src: image.src.small,
          maxWidth: 480,
        },
      ]}
    />
    <div className="image-content__content">
      <Typography className="image-content__title" variant={VARIANT.H2}>
        {title}
      </Typography>
      <Content raw>{content}</Content>
      <div className="image-content__links">
        {cta.map((item, key) =>
          renderContainer(
            <InlineButton className="image-content__link" key={`image-content-cta-${key}`}>
              {item.text}
            </InlineButton>,
            item.url,
            key,
          ),
        )}
      </div>
    </div>
  </FullRow>
);

ImageContent.propTypes = {
  content: PropTypes.string.isRequired,
  cta: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  image: responsiveImagePropType,
  renderContainer: PropTypes.func,
  title: PropTypes.string.isRequired,
};

ImageContent.defaultProps = {
  renderContainer: children => children,
};

export default ImageContent;
