import {cancelableGet} from './Client';

export default locale => ({
  all: () =>
    cancelableGet(`/api/context?lang=${locale}`, {
      // Added the query-param to prevent axios from caching the request between languages
      headers: {
        'Accept-Language': locale,
      },
    }),
});
