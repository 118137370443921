import Typography, {VARIANT} from '../../atoms/Typography/Typography';

import React from 'react';
import Column from '../../layouts/Column/Column';

const HeroLabel = ({label}) => (
  <Column className="hero__label-name" scrollAnimation={false}>
    <Typography variant={VARIANT.BODY}>{label}</Typography>
  </Column>
);

export default HeroLabel;
