import React, {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import {FormattedNavLink} from '@foes/react-i18n-routing';
import {RESOURCES} from '../../../routing/resources/routes';

import GenericItems from './../../layouts/GenericItems/GenericItems';
import Main from './../../layouts/Main/Main';
import ContentWithSidebar from '../../layouts/ContentWithSidebar/ContentWithSidebar';
import Column from '../../layouts/Column/Column';
import Hero from '../../compositions/Hero/Hero';
import CardImage from '../../components/CardImage/CardImage';
import InlineButton from '../../atoms/InlineButton/InlineButton';
import {RATIO} from '../../atoms/Picture/Picture';
import Typography, {VARIANT} from '../../atoms/Typography/Typography';

import ContactForm from '../../pages/BackendPage/Contact/ContactForm';

import './Resource.css';

const genericItemsId = 'resource-content';

const ResourceDetail = ({resource}) => (
  <ContentWithSidebar
    left={
      <Column className="resource__label">
        <Typography variant={VARIANT.BODY}>{<FormattedMessage id="resource.resources" />}</Typography>
      </Column>
    }
  >
    <Column>
      <CardImage
        horizontal={true}
        image={resource.image}
        ratio={RATIO.R_16_9}
        renderContainer={children => children}
        title={<Typography variant={VARIANT.H3}>{resource.title}</Typography>}
        withHover
      />
      <div
        className="typography typography--body content resource__text"
        dangerouslySetInnerHTML={{__html: resource.content}}
      ></div>
    </Column>
  </ContentWithSidebar>
);

const ResourceFormTitle = () => (
  <ContentWithSidebar className="resource__form-title">
    <Column>
      <Typography variant={VARIANT.H2}>{<FormattedMessage id="resource.formtitle" />}</Typography>
    </Column>
  </ContentWithSidebar>
);

const ResourceForm = ({portalId, formId, resourceUrl}) => (
  <ContentWithSidebar className="resource__form">
    <Column>
      <ContactForm
        className="resource__contact-form"
        formInfo={{formId: formId, portalId: portalId, resourceUrl: resourceUrl}}
        invertedColor
        visibleFromStart
      />
    </Column>
  </ContentWithSidebar>
);

const ResourceBackLink = () => (
  <ContentWithSidebar
    className="resource__backlink"
    left={
      <Column>
        <FormattedNavLink name={RESOURCES}>
          <InlineButton hideUnderline={true} withHover={false}>
            <Typography variant={VARIANT.BODY}>
              <FormattedMessage id="resource.back" />
            </Typography>
          </InlineButton>
        </FormattedNavLink>
      </Column>
    }
  ></ContentWithSidebar>
);

const Resource = ({content: resource}) => {
  useEffect(() => {
    console.log('Resource');
  }, []);

  return (
    <Main className="resource">
      <Hero title=""></Hero>

      {resource && <ResourceDetail resource={resource} />}

      {resource.settings.resourceHubspot && (
        <>
          <ResourceFormTitle />
          <ResourceForm
            formId={resource.settings.resourceHubspot.formId}
            portalId={resource.settings.resourceHubspot.portalId}
            resourceUrl={resource.resource.url}
          />
        </>
      )}

      {resource.blocks && resource.blocks.items && (
        <GenericItems className="resource__generic-items" id={genericItemsId} items={resource.blocks.items} />
      )}

      <ResourceBackLink />
    </Main>
  );
};

export default React.memo(Resource);
