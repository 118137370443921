import React from 'react';
import PropTypes from 'prop-types';

import responsiveImagePropType from './../../_propTypes/responsiveImagePropType';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import InlineButton from './../../atoms/InlineButton/InlineButton';

import CardImage from './../CardImage/CardImage';

const ItemPreview = ({footerText, image, renderContainer, title, withHover}) => (
  <CardImage
    footer={footerText && <InlineButton>{footerText}</InlineButton>}
    image={image}
    renderContainer={renderContainer}
    title={<Typography variant={VARIANT.H2}>{title}</Typography>}
    withHover={withHover}
  />
);

ItemPreview.propTypes = {
  footerText: PropTypes.string,
  image: responsiveImagePropType,
  renderContainer: PropTypes.func,
  title: PropTypes.string.isRequired,
  withHover: PropTypes.any,
};

ItemPreview.defaultProps = {
  renderContainer: children => children,
};

export default ItemPreview;
