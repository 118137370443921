import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {renderRoutes} from 'react-router-config';
import get from 'lodash.get';
import classNames from 'classnames';

import NotFound from './../../templates/NotFound/NotFound';

import Footer from './../Footer/Footer';
import Header from './../Header/Header';

const Body = ({error, page, route}) => {
  const inverted =
    get(page, 'resource.inverted', false) ||
    get(page, 'resource.content.template') === 'white' ||
    get(page, 'resource.content.type') === 'resource';
  const shouldShowHeader = !(get(page, 'resource.content.template') === 'landing');
  const shouldShowFooter = error || (page && page.resource);
  const shouldHideFooterFields = get(page, 'resource.content.footer.hideFields');

  return (
    <div className={classNames('body', {'body--inverted': inverted})}>
      {shouldShowHeader && <Header inverted={inverted} />}
      {error ? <NotFound error={error} /> : renderRoutes(route.routes)}
      {shouldShowFooter && (
        <Footer
          settings={page.resource ? page.resource.settings : {}}
          shouldHideFooterFields={shouldHideFooterFields}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  error: get(state, 'error.backendPage'),
  inverted: get(state, 'api.backendPage.resource.inverted', false),
  page: get(state, 'api.backendPage'),
});

export default compose(
  connect(mapStateToProps),
  React.memo,
)(Body);
