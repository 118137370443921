import {useEffect} from 'react';
import {withRouter} from 'react-router';

import isSSR from './../../config/isSSR';

const ScrollToTop = ({location}) => {
  useEffect(() => {
    if (!isSSR()) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return null;
};

export default withRouter(ScrollToTop);
