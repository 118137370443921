import {LOCATION_CHANGE} from 'connected-react-router';

export const COUNTER_SENDING_DATA = 'COUNTER_SENDING_DATA';
export const COUNTER_DATA_SENT = 'COUNTER_DATA_SENT';
export const COUNTER_SEND_FAILED = 'COUNTER_DATA_FAILED';

const initialState = {
  data: null,
  error: false,
  submitting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COUNTER_SENDING_DATA: {
      return {
        ...state,
        data: null,
        error: false,
        submitting: true,
      };
    }

    case COUNTER_DATA_SENT: {
      return {
        data: action.data,
        error: false,
        submitting: false,
      };
    }

    case COUNTER_SEND_FAILED: {
      return {
        data: action.responseCode,
        error: false,
        submitting: false,
      };
    }

    case LOCATION_CHANGE: {
      return 'key' in action.payload.location ? {...state, data: null, error: null, submitting: false} : state;
    }

    default:
      return state;
  }
};
