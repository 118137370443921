import React, {useState} from 'react';

import Typography from './../../atoms/Typography/Typography';

import StatsSummary from './../../components/StatsSummary/StatsSummary';

import Column from './../../layouts/Column/Column';
import ContentWithSidebar from './../../layouts/ContentWithSidebar/ContentWithSidebar';
import Row from './../../layouts/Row/Row';

import './StatsSummaries.css';

const StatsSummaries = ({elements, id}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const selected = elements[selectedIndex];

  return (
    <ContentWithSidebar
      className="stats-summaries"
      id={id ? id : null}
      left={
        elements.length > 1 && (
          <Row className="stats-summaries__selector-wrapper" scrollAnimation={false}>
            {elements.map((element, index) => (
              <Typography
                className="stats-summaries__selector"
                key={index}
                onClick={() => setSelectedIndex(index)}
                tag={Column}
              >
                {selectedIndex === index ? '●' : '○'} {element.listTitle}
              </Typography>
            ))}
          </Row>
        )
      }
    >
      <StatsSummary scrollAnimation={false} stats={selected.elements} title={selected.title} />
    </ContentWithSidebar>
  );
};

export default StatsSummaries;
