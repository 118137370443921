import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import responsiveImagePropType from './../../_propTypes/responsiveImagePropType';

import Picture, {RATIO} from './../../atoms/Picture/Picture';

import './CardImage.css';

const CardImage = ({
  className,
  contain,
  cover,
  divider,
  horizontal,
  horizontalReverse,
  image,
  ratio,
  pretitle,
  title,
  subtitle,
  content,
  footer,
  renderContainer,
  withHover,
}) =>
  renderContainer(
    <div
      className={classNames(
        'card-image',
        {'card-image--horizontal': horizontal},
        {'card-image--horizontal-reverse': horizontalReverse},
        {'card-image--with-hover': withHover},
        {divider__item: divider},
        className,
      )}
    >
      {image && (
        <Picture
          alt={image.alt}
          className="card-image__wrapper"
          contain={contain}
          cover={cover}
          imageClassName="card-image__image"
          pictureClassName="card-image__picture"
          ratio={ratio}
          src={image.src.large || image.src.original}
          srcSet={[
            {
              src: image.src.small,
              maxWidth: 480,
            },
          ]}
        />
      )}
      <div className="card-image__content">
        <div className="card-image__text">
          {pretitle}
          {title}
          {subtitle}
          {content}
        </div>
        {footer && <div className="card-image__footer">{footer}</div>}
      </div>
    </div>,
  );

/*
// All Pretitle, Title, Subtitle, Content and Footer are optional props
// But at least one of them should be provided for the component to work properly
*/
CardImage.propTypes = {
  className: PropTypes.string,
  contain: PropTypes.bool,
  content: PropTypes.node,
  cover: PropTypes.bool,
  /* Any node is accepted, but for the component to work properly, the footer should be only Button or InlineButton */
  footer: PropTypes.node,
  /* Horizontal has the image on the left, horizontalReverse on the right */
  horizontal: PropTypes.bool,
  horizontalReverse: PropTypes.bool,
  /* */
  image: responsiveImagePropType,
  pretitle: PropTypes.node,
  ratio: PropTypes.oneOf(Object.values(RATIO)),
  renderContainer: PropTypes.func.isRequired,
  subtitle: PropTypes.node,
  title: PropTypes.node,
  withHover: PropTypes.any,
};

CardImage.defaultProps = {
  cover: true,
  horizontal: false,
  horizontalReverse: false,
  ratio: RATIO.R_16_9,
  renderContainer: children => children,
  withHover: false,
};

export default CardImage;
