// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'objectFitPolyfill';
import cssVars from 'css-vars-ponyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import createStore from './store/store';

import App from './App';

import './ui/_theme/Theme';

cssVars();

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;
const {store, history} = createStore({preloadedState: window.__PRELOADED_STATE__});

if (process.env.NODE_ENV !== 'production') {
  const axe = require('react-axe');

  axe(React, ReactDOM, 1000);
}

renderMethod(<App history={history} store={store} />, document.getElementById('root'));
