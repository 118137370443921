import React from 'react';
import {getLocale} from '@foes/react-i18n-routing/dist/common/locale';

import i18n from './../../../../i18n';
import {HOME} from './../../../../routing/routes';

import Typography from './../../../atoms/Typography/Typography';

import './LanguageSelector.css';

const getLanguageString = locale => {
  switch (locale) {
    case 'en': {
      return 'Eng';
    }
    case 'es': {
      return 'Esp';
    }
    case 'fr': {
      return 'Fra';
    }

    default: {
      return null;
    }
  }
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
const LanguageSelector = () => (
  <div className="language-selector">
    <div className="language-selector__options">
      <span className="language-selector__option language-selector__option--selected">
        <Typography className="language-selector__option-current">{getLanguageString(getLocale())}</Typography>
      </span>
      {i18n.locales
        .filter(locale => locale !== getLocale())
        .map(locale => (
          <a className="language-selector__option" href={i18n.formatIntlRoute(HOME, {}, locale)} key={locale}>
            <Typography>{getLanguageString(locale)}</Typography>
          </a>
        ))}
    </div>
  </div>
);

export default LanguageSelector;
