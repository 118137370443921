import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import resourcePreviewPropType from './../../_propTypes/resourcePreviewPropType';

import InlineButton from './../../atoms/InlineButton/InlineButton';
import {RATIO} from '../../atoms/Picture/Picture';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import CardImage from './../CardImage/CardImage';

import './ResourcePreview.css';

const ResourcePreview = ({resource, renderContainer}) => (
  <CardImage
    footer={
      <InlineButton>
        <FormattedMessage id="resource_preview.view" />
      </InlineButton>
    }
    horizontal={true}
    image={resource.image}
    ratio={RATIO.R_16_9}
    renderContainer={renderContainer}
    title={<Typography variant={VARIANT.H3}>{resource.title}</Typography>}
    withHover
  />
);

ResourcePreview.propTypes = {
  renderContainer: PropTypes.func,
  resource: resourcePreviewPropType.isRequired,
};

ResourcePreview.defaultProps = {
  renderContainer: children => children,
};

export default React.memo(ResourcePreview);
