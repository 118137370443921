import React from 'react';
import Column from '../../../layouts/Column/Column';

import Typography, {VARIANT} from './../../../atoms/Typography/Typography';

import CmsLink from './../../../components/CmsLink/CmsLink';
import ItemPreview from './../../../components/ItemPreview/ItemPreview';

import Slider from './../../../compositions/Slider/Slider';

import ContentWithSidebar from './../../../layouts/ContentWithSidebar/ContentWithSidebar';

import './HomepageElement.css';

const HomepageElement = ({element}) => {
  const PreviewWrapper = element.elements.length > 1 ? Slider : React.Fragment;
  const previewParams = element.elements.length > 1 ? {slidesPerBreakpoint: {S: 1}, withButtons: false} : {};

  return (
    <ContentWithSidebar
      className="homepage__element"
      left={
        <Column>
          <Typography variant={VARIANT.BIG_BODY}>{element.label}</Typography>
        </Column>
      }
    >
      <Column className="homepage__element-content">
        <PreviewWrapper {...previewParams}>
          {element.elements.map((item, index) => (
            <ItemPreview
              footerText={item.cta.text}
              image={item.image}
              key={index}
              renderContainer={children => <CmsLink slug={item.cta.url}>{children}</CmsLink>}
              title={item.title}
              withHover
            />
          ))}
        </PreviewWrapper>
      </Column>
    </ContentWithSidebar>
  );
};

export default HomepageElement;
