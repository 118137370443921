import React from 'react';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import ContactForm from './../../pages/BackendPage/Contact/ContactForm';

import FullRow from '../../layouts/FullRow/FullRow';
import Column from './../../layouts/Column/Column';

import './CmsContactForm.css';

const CmsContactForm = ({title, hubspot}) => (
  <FullRow>
    <Column className="cms-contact-form__column">
      <div className="cms-contact-form__content">
        <Column>
          <Typography className="cms-contact-form__title" tag="p" variant={VARIANT.H2}>
            {title}
          </Typography>
          <ContactForm formInfo={hubspot} invertedColor visibleFromStart />
        </Column>
      </div>
    </Column>
  </FullRow>
);

export default React.memo(CmsContactForm);
