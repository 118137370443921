import backendPageGet from './../../api/Backend/Page';
import backendContextGet from './../../api/Backend/Context';
import {API_FETCHING, API_RECEIVED, API_FAILED, API_CANCELED} from './ApiReducer';

import isSSR from './../../config/isSSR';
import ClientRequestQueue from './ClientRequestQueue';
import {httpRequestError} from '../Error/ErrorActions';

const backendResource = (type, method) => ({key, keyFilter, params} = {}) => async dispatch => {
  dispatch({
    type: API_FETCHING,
    resourceType: type,
  });

  try {
    const request = method(params);

    if (!isSSR()) {
      ClientRequestQueue.cancelNonMatchingKeyRequests({request, key, keyFilter});
    }
    const resource = await request;
    dispatch({
      type: API_RECEIVED,
      resourceType: type,
      resource: resource.data,
    });
  } catch (error) {
    if (error.message && error.message.cancelled) {
      dispatch({
        type: API_CANCELED,
        resourceType: type,
      });

      return;
    }
    dispatch({
      type: API_FAILED,
      resourceType: type,
    });

    if (error.response) {
      dispatch(httpRequestError(type)({...error.response}));
    } else {
      dispatch(httpRequestError(type)({status: 503, data: {message: error.toString()}}));
    }
  }
};

export const getBackendPage = locale => backendResource('backendPage', backendPageGet(locale).byPath);
export const getBackendContext = locale => backendResource('backendContext', backendContextGet(locale).all);
