import React, {useState, useEffect, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {withRouter} from 'react-router';

// import {useLocation} from 'react-router-dom';
import isEqual from 'lodash.isequal';
import uniqWith from 'lodash.uniqwith';

import RoutingHero from './../../_bridges/RoutingHero/RoutingHero';
import RoutingResourcePreview from '../../_bridges/RoutingResourcePreview/RoutingResourcePreview';

import Column from '../../layouts/Column/Column';
import ContentWithSidebar from '../../layouts/ContentWithSidebar/ContentWithSidebar';
import GenericItems from './../../layouts/GenericItems/GenericItems';
import Main from './../../layouts/Main/Main';

import InlineTag from '../../atoms/InlineTag/InlineTag';
import Typography, {VARIANT} from '../../atoms/Typography/Typography';

import './Resources.css';

const flatSectors = resources =>
  uniqWith(resources.reduce((carry, resource) => carry.concat(resource.sectors), []), isEqual).sort(
    (sector, nextSector) => (sector.order > nextSector.order ? 1 : -1),
  );

const TagButton = ({active, tag}) => (
  <a href={`?sector=${tag.slug}`}>
    <InlineTag inverted={active}>{tag.name}</InlineTag>
  </a>
);
const Resources = ({content, location}) => {
  const search = location.search;

  const sectors = flatSectors(content.resources);
  const [activeSector, setSector] = useState(sectors.length > 0 ? sectors[0].slug : null);

  const updateActiveSector = useCallback(sector => {
    setSector(activeSector !== null && activeSector.slug === sector ? null : sector);
  });

  const filterResources = useCallback(resources => {
    if (activeSector === null) {
      return resources;
    }
    // eslint-disable-next-line max-len
    return resources.filter(resource => resource.sectors.filter(sector => activeSector === sector.slug).length === 1);
  });

  useEffect(() => {
    const newSector = new URLSearchParams(search).get('sector');
    if (newSector === null) {
      setSector(sectors.length > 0 ? sectors[0].slug : null);
    } else {
      updateActiveSector(newSector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Main className="resources">
      {content.hero && <RoutingHero className="resources__hero" hero={content.hero} />}
      <ContentWithSidebar
        left={
          <Column>
            <Typography variant={VARIANT.BODY}>{<FormattedMessage id="tags.showing" />}</Typography>
          </Column>
        }
      >
        <Column className="resources__filters">
          {sectors &&
            sectors
              .sort((a, b) => parseInt(a.order, 10) - parseInt(b.order, 10))
              .map((sector, key) => <TagButton active={sector.slug === activeSector} key={key} tag={sector} />)}
        </Column>
      </ContentWithSidebar>

      {content.resources && content.resources.length > 0 && (
        <>
          {filterResources(content.resources).map((resource, index) => (
            <RoutingResourcePreview key={index} resource={resource} />
          ))}
        </>
      )}

      {content.items && <GenericItems className="resources__generic-items" items={content.items} />}
    </Main>
  );
};

export default React.memo(withRouter(Resources));
