import React, {useRef, useState, useEffect} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {animated, useTransition} from 'react-spring';
import {compose} from 'redux';
import get from 'lodash.get';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import InlineButton from './../../../atoms/InlineButton/InlineButton';

import './ContactForm.css';

const getElementHeight = ref => (ref && ref.current ? ref.current.getBoundingClientRect().height : 0);

const ContactFormContent = ({formInfo, id}) => {
  let container = React.createRef();

  useEffect(() => {
    const script = document.createElement('script');
    script.appendChild(
      document.createTextNode(`hbspt.forms.create({
        portalId: "${formInfo.portalId}",
        formId: "${formInfo.formId}",
        onFormSubmit: function($form) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'contact form sent',
            'Type': 'Contact'
          });
        }
      });`),
      (function() {
        window.jQuery =
          window.jQuery ||
          function(nodeOrSelector) {
            if (typeof nodeOrSelector == 'string') {
              return document.querySelector('.hbspt-form');
            }
            return nodeOrSelector;
          };
      })(),
    );
    container.appendChild(script);
    // eslint-disable-next-line

    if (formInfo.resourceUrl) {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.type === 'childList') {
            const link_recurso = document.querySelector('[name=link_recurso]');
            if (link_recurso) {
              link_recurso.value = formInfo.resourceUrl;
              observer.disconnect();
            }
          }
        });
      });
      observer.observe(document.querySelector('main'), {childList: true, subtree: true});
    }

    window.addEventListener('message', event => {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
        const firstname = event.data.data.find(item => item.name === 'firstname');
        const phone = event.data.data.find(item => item.name === 'phone');
        const email = event.data.data.find(item => item.name === 'email');

        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
          'hs-form-guid': event.data.id,
          fn: firstname ? firstname.value : '',
          ph: phone ? phone.value : '',
          em: email ? email.value : '',
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="contact-form__content"
      id={id}
      ref={divRef => {
        container = divRef;
      }}
    />
  );
};

const visibleStyle = {height: 'auto', opacity: 1, overflow: 'visible'};
const hiddenStyle = {opacity: 0, height: 0, overflow: 'hidden'};

const ContactForm = ({className, formInfo, invertedColor, visibleFromStart, ...rest}) => {
  const innerRef = useRef(null);
  const [visible, setVisible] = useState(visibleFromStart ? true : false);

  const transitions = useTransition(visible, null, {
    enter: () => async (next, cancel) => {
      const height = getElementHeight(innerRef);

      cancel();

      await next({height: height, opacity: 1, overflow: 'hidden'});
      await next(visibleStyle);
    },
    leave: () => async (next, cancel) => {
      const height = getElementHeight(innerRef);

      cancel();

      await next({height: height, overflow: 'hidden'});
      await next(hiddenStyle);
    },
    duration: 200,
    from: hiddenStyle,
    unique: true,
  });

  return (
    <div className={classNames('contact-form', className, {'contact-form--inverted': invertedColor})}>
      {!visibleFromStart && (
        <InlineButton inverted={invertedColor} onClick={() => setVisible(!visible)}>
          <FormattedMessage id={visible ? 'contact.form.close' : 'contact.form.open'} />
        </InlineButton>
      )}

      {transitions.map(({item, props, key}) =>
        item ? (
          <animated.div key={key} style={props}>
            <ContactFormContent formInfo={formInfo} formRef={innerRef} invertedColor={invertedColor} {...rest} />
          </animated.div>
        ) : null,
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  formResponse: get(state, 'contactForm.data'),
  submitting: get(state, 'contactForm.submitting'),
});

ContactForm.propTypes = {
  invertedColor: PropTypes.bool,
  visibleFromStart: PropTypes.bool,
};

ContactForm.defaultProps = {
  invertedColor: false,
  visibleFromStart: false,
};

export default compose(
  React.memo,
  injectIntl,
  connect(mapStateToProps),
)(ContactForm);
