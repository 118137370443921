import React from 'react';

import Picture from './../../../atoms/Picture/Picture';
import Typography, {VARIANT} from './../../../atoms/Typography/Typography';
import Video from './../../../atoms/Video/Video';

import Slider from './../../../compositions/Slider/Slider';

import Column from './../../Column/Column';
import FullRow from './../../FullRow/FullRow';
import Row from './../../Row/Row';

import './Gallery.css';

const GALLERY_TYPES = {
  FULL_ROW: 'full_row',
};

const videoPlayerProps = {loop: true, modestbranding: true, playsinline: true};

const getSliderBreakpointsPerType = type => {
  if (type === GALLERY_TYPES.FULL_ROW) {
    return {S: 1};
  }

  return {S: 1, SM: 2, M: 3, XL: 4};
};

const GalleryTitle = ({title}) =>
  title ? (
    <Row className="gallery__title" scrollAnimation={false}>
      <Column>
        <Typography variant={VARIANT.H2}>{title}</Typography>
      </Column>
    </Row>
  ) : null;

const GalleryImage = ({alt, src}) => (
  <Picture alt={alt} src={src.large || src.original} srcSet={[{src: src.medium, maxWidth: 1919}]} />
);

const GalleryVideo = ({url}) => (
  <div className="gallery__video">
    <Video playerProps={videoPlayerProps} src={url} />
  </div>
);

const Gallery = ({elements, id, galleryType, title}) => (
  <Column>
    <FullRow className="gallery" id={id ? id : null}>
      <GalleryTitle title={title} />
      <div className="gallery__column">
        <Slider
          slidesPerBreakpoint={getSliderBreakpointsPerType(galleryType)}
          thinner={galleryType === GALLERY_TYPES.FULL_ROW}
          withButtons={false}
          withGradient
        >
          {elements.map(({type, element}, innerIndex) => {
            if (type === 'image') {
              return <GalleryImage key={innerIndex} {...element} />;
            }

            if (type === 'video') {
              return <GalleryVideo key={innerIndex} {...element} />;
            }

            return null;
          })}
        </Slider>
      </div>
    </FullRow>
  </Column>
);

export default Gallery;
