import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withSize from './../../../_packages/resize/ui/withSize';
import {valuePerBreakpointForConfig} from './../../_config/breakpoints';
import prefixStyles from './../../_util/prefixStyles';

import Row from './../Row/Row';
import Column from './../Column/Column';

const Grid = ({
  children,
  className,
  columnsPerBreakpoint,
  itemClassName,
  scrollAnimation,
  tag: GridTag,
  windowWidth,
  ...rest
}) => {
  const columnsPerView = valuePerBreakpointForConfig(columnsPerBreakpoint)(windowWidth);

  return (
    <GridTag className={classNames('grid', className)} scrollAnimation={scrollAnimation} {...rest}>
      {React.Children.map(children, (item, index) => (
        <Column
          className={classNames('grid__item', itemClassName)}
          key={index}
          style={prefixStyles({
            flexBasis: `${(1 / columnsPerView) * 100}%`,
            maxWidth: `${(1 / columnsPerView) * 100}%`,
          })}
        >
          {item}
        </Column>
      ))}
    </GridTag>
  );
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  columnsPerBreakpoint: PropTypes.object,
  itemClassName: PropTypes.string,
  scrollAnimation: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Grid.defaultProps = {
  columnsPerBreakpoint: {S: 1},
  scrollAnimation: true,
  tag: Row,
};

export default React.memo(withSize(Grid));
