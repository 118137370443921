import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';
import Typography, {VARIANT} from '../../atoms/Typography/Typography';

import BREAKPOINTS, {valuePerBreakpointForConfig} from './../../_config/breakpoints';

import withSize from './../../../_packages/resize/ui/withSize';

import Button from './../../atoms/Button/Button';
import Iconography, {ICON} from './../../atoms/Iconography/Iconography';

import './Slider.css';

const Flickity = typeof window !== 'undefined' ? require('react-flickity-component') : () => null;
typeof window !== 'undefined' && require('flickity-fullscreen');

const getFlickityOptions = (infiniteLoop, childrenLength) => ({
  adaptiveHeight: false,
  cellAlign: 'left',
  bgLazyLoad: 1,
  wrapAround: infiniteLoop,
  prevNextButtons: false,
  pageDots: childrenLength > 1,
  reloadOnUpdate: true,
  cellSelector: '.slider__card',
  freeScroll: false,
  contain: true,
  groupCells: true,
  selectedAttraction: 0.015,
  friction: 0.2,
});

class Slider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isFullscreen: false,
    };

    this.myCustomNext = this.myCustomNext.bind(this);
    this.myCustomPrevious = this.myCustomPrevious.bind(this);
    this.getSlidesWidth = this.getSlidesWidth.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);

    this.touchStartCoords = null;
    this.touchingCarousel = false;
  }

  handleDisabledButton() {
    const buttonPrevious = this.flkty.element.parentNode.querySelector('.slider__button-previous');
    const buttonNext = this.flkty.element.parentNode.querySelector('.slider__button-next');
    if (buttonPrevious) {
      this.flkty.selectedIndex === 0
        ? buttonPrevious.classList.add('is-disabled')
        : buttonPrevious.classList.remove('is-disabled');
    }

    if (buttonNext) {
      this.flkty.selectedIndex === this.flkty.slides.length - 1
        ? buttonNext.classList.add('is-disabled')
        : buttonNext.classList.remove('is-disabled');
    }
  }

  componentDidMount() {
    // Fix scrolling in iOS 13
    document.body.addEventListener('touchstart', this.touchStartListener);
    document.body.addEventListener('touchmove', this.touchMoveListener, {passive: false});

    this.handleDisabledButton();
    this.flkty.on('change', () => this.handleDisabledButton());
  }

  componentWillUnmount() {
    document.body.removeEventListener('touchstart', this.touchStartListener);
    document.body.removeEventListener('touchstart', this.touchMoveListener);
  }

  touchStartListener(e) {
    if (e.target.closest('.flickity-slider')) {
      this.touchingCarousel = true;
    } else {
      this.touchingCarousel = false;
      return;
    }

    this.touchStartCoords = {
      x: e.touches[0].pageX,
      y: e.touches[0].pageY,
    };
  }

  touchMoveListener(e) {
    if (!(this.touchingCarousel && e.cancelable)) {
      return;
    }

    const moveVector = {
      x: e.touches[0].pageX - this.touchStartCoords.x,
      y: e.touches[0].pageY - this.touchStartCoords.y,
    };

    if (Math.abs(moveVector.x) > 4) {
      e.preventDefault();
    }
  }

  myCustomNext() {
    this.flkty.next();
  }

  myCustomPrevious() {
    this.flkty.previous();
  }

  getSlidesWidth(windowWidth, slidesPerBreakpoint) {
    return `${100 / valuePerBreakpointForConfig(slidesPerBreakpoint)(windowWidth)}%`;
  }

  toggleFullscreen(index) {
    const {isFullscreen} = this.state;
    this.setState({isFullscreen: !isFullscreen}, () => {
      this.flkty.select(index);
      this.flkty.toggleFullscreen();
    });
  }

  render() {
    const {isFullscreen} = this.state;
    const {
      allowFullScreen,
      children,
      gallery,
      noMargin,
      slidesPerBreakpoint,
      windowWidth,
      thinner,
      withButtons,
      withGradient,
      infiniteLoop,
      intl,
    } = this.props;
    var slideWidth =
      gallery && windowWidth >= BREAKPOINTS.XL ? 'max-content' : this.getSlidesWidth(windowWidth, slidesPerBreakpoint);

    return (
      <div className={classNames('slider', {'slider--with-gradient': withGradient, 'slider--thinner': thinner})}>
        {withButtons && children.length >= valuePerBreakpointForConfig(slidesPerBreakpoint)(windowWidth) && (
          <div className="slider__buttons">
            <Button
              aria-label={intl.formatMessage({id: 'slider.button.previous'})}
              className="slider__button-previous"
              onClick={this.myCustomPrevious}
            >
              <Typography variant={VARIANT.BIG_BODY}>{'⟵'}</Typography>
            </Button>
            <Button
              aria-label={intl.formatMessage({id: 'slider.button.next'})}
              className="slider__button-next"
              onClick={this.myCustomNext}
            >
              <Typography variant={VARIANT.BIG_BODY}>{'⟶'}</Typography>
            </Button>
          </div>
        )}
        <Flickity
          className={'slider__carousel'}
          disableImagesLoaded={false}
          elementType={'div'}
          flickityRef={c => (this.flkty = c)}
          options={getFlickityOptions(infiniteLoop, React.Children.toArray(children).length)}
          reloadOnUpdate
          static
        >
          {React.Children.map(children, (child, index) => (
            <div
              className={classNames('slider__card', {'slider__card--no-margin': noMargin})}
              key={index}
              style={{width: isFullscreen ? '100%' : slideWidth}}
            >
              {child}
              {allowFullScreen && windowWidth >= BREAKPOINTS.XXL && (
                <Button
                  aria-label={intl.formatMessage({
                    id: isFullscreen ? 'slider.button.fullscreen_close' : 'slider.button.fullscreen_open',
                  })}
                  className={classNames('slider__fullscreen', {'slider__fullscreen--active': isFullscreen})}
                  onClick={() => this.toggleFullscreen(index)}
                >
                  <Iconography icon={isFullscreen ? ICON.FULLSCREEN_CLOSE : ICON.FULLSCREEN} />
                </Button>
              )}
            </div>
          ))}
          {allowFullScreen && windowWidth < BREAKPOINTS.XXL && (
            <Button
              aria-label={intl.formatMessage({
                id: isFullscreen ? 'slider.button.fullscreen_close' : 'slider.button.fullscreen_open',
              })}
              className={classNames('slider__fullscreen', {'slider__fullscreen--active': isFullscreen})}
              onClick={() => this.toggleFullscreen(this.flkty.selectedIndex)}
            >
              <Iconography icon={isFullscreen ? ICON.FULLSCREEN_CLOSE : ICON.FULLSCREEN} />
            </Button>
          )}
        </Flickity>
      </div>
    );
  }
}

Slider.propTypes = {
  allowFullScreen: PropTypes.bool,
  gallery: PropTypes.bool,
  infiniteLoop: PropTypes.bool,
  noMargin: PropTypes.bool,
  slidesPerBreakpoint: PropTypes.object,
  thinner: PropTypes.bool,
  withButtons: PropTypes.bool,
  withGradient: PropTypes.bool,
};

Slider.defaultProps = {
  allowFullScreen: false,
  gallery: false,
  infiniteLoop: false,
  noMargin: false,
  slidesPerBreakpoint: {S: 1, L: 2, XXXXL: 3},
  thinner: false,
  withButtons: true,
  withGradient: false,
};

export default compose(
  withSize,
  injectIntl,
  React.memo,
)(Slider);
