import React from 'react';

import RoutingHero from './../../_bridges/RoutingHero/RoutingHero';

import GenericItems from './../../layouts/GenericItems/GenericItems';
import Main from './../../layouts/Main/Main';
import ResourcePreviewList from '../../compositions/ResourcePreviewList/ResourcePreviewList';

import './CaseStudy.css';

const genericItemsId = 'case-study-content';

const CaseStudy = ({content: caseStudy}) => (
  <Main className="case-study">
    {caseStudy.hero && <RoutingHero hero={caseStudy.hero} />}

    {caseStudy.blocks.items && (
      <GenericItems className="case-study__generic-items" id={genericItemsId} items={caseStudy.blocks.items} />
    )}

    {caseStudy.resources.items && caseStudy.resources.items.length > 0 && (
      <div className="case-study__resources">
        <ResourcePreviewList allowFirst={true} resources={caseStudy.resources.items} />
      </div>
    )}
  </Main>
);

export default React.memo(CaseStudy);
