import React from 'react';
import PropTypes from 'prop-types';

import responsiveImagePropType from './../../_propTypes/responsiveImagePropType';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Picture from '../../atoms/Picture/Picture';

import Section from './../../components/Section/Section';
import Content from './../../components/Content/Content';

import Grid from './../../layouts/Grid/Grid';
import FullRow from '../../layouts/FullRow/FullRow';
import Row from '../../layouts/Row/Row';

import './ListImageContent.css';

const ListImageContent = ({cta, id, title, columns, fullWidth}) => (
  <Section className="list-image-content__title" contentTag={fullWidth ? FullRow : Row} id={id ? id : null}>
    <Typography className="list-image-content__title" variant={VARIANT.H2}>
      {title}
    </Typography>
    <Grid
      className="list-image-content__grid"
      columnsPerBreakpoint={{S: 1, M: 2, L: columns || 2}}
      itemClassName="list-image-content__grid-item"
      scrollAnimation={false}
      tag={fullWidth ? FullRow : Row}
    >
      {cta.map((item, index) => (
        <div className="list-image-content__item" key={index}>
          <Picture
            alt={item.image.alt}
            className="list-image-content__wrapper"
            imageClassName="list-image-content__image"
            pictureClassName="list-image-content__picture"
            src={item.image.src.medium || item.image.src.original}
            srcSet={[
              {
                src: item.image.src.small,
                maxWidth: 480,
              },
            ]}
          />
          <div className="list-image-content__item-content">
            <Typography className="list-image-content__item-title" variant={VARIANT.BIG_BODY}>
              {item.title}
            </Typography>

            <Content className="list-image-content__item-text" raw tag="div">
              {item.text}
            </Content>
          </div>
        </div>
      ))}
    </Grid>
  </Section>
);

ListImageContent.propTypes = {
  cta: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      image: responsiveImagePropType,
    }),
  ),
  title: PropTypes.string.isRequired,
};

ListImageContent.defaultProps = {};

export default React.memo(ListImageContent);
