import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '../Typography/Typography';

import './InlineTag.css';

const InlineTag = ({children, className, inverted, tag, withHover, ...rest}) => (
  <Typography
    className={classNames(
      'inline-tag',
      {'inline-tag--inverted': inverted, 'inline-tag--with-hover': withHover},
      className,
    )}
    tag={tag}
    {...rest}
  >
    {children}
  </Typography>
);

InlineTag.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  inverted: PropTypes.bool,
  tag: PropTypes.string,
  withHover: PropTypes.any,
};

InlineTag.defaultProps = {
  inverted: false,
  withHover: true,
};

export default InlineTag;
