import React from 'react';
import PropTypes from 'prop-types';

import InlineButton from './../../atoms/InlineButton/InlineButton';
import {VARIANT} from './../../atoms/Typography/Typography';

import Content from './../Content/Content';
import Section from './../Section/Section';

import FullRow from './../../layouts/FullRow/FullRow';

import './Contact.css';

const ContactButton = ({children}) => <InlineButton inverted>{children}</InlineButton>;

const Contact = ({children, title}) => (
  <FullRow className="contact">
    <Section
      scrollAnimation={false}
      title={title}
      titleParams={{raw: true}}
      titleTag={Content}
      titleVariant={VARIANT.H1}
    >
      {children}
    </Section>
  </FullRow>
);

Contact.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};

ContactButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export {ContactButton};
export default React.memo(Contact);
