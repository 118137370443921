import React from 'react';

import Column from './../../Column/Column';
import FullRow from './../../FullRow/FullRow';

import './Divider.css';

const Divider = () => (
  <FullRow className="divider" scrollAnimation={false}>
    <Column className="divider__column">
      <hr className="divider__item" />
    </Column>
  </FullRow>
);

export default Divider;
