import React from 'react';
import {FormattedMessage} from 'react-intl';
import {FormattedNavLink} from '@foes/react-i18n-routing';

import {RESOURCES} from '../../../routing/resources/routes';

import RoutingResourcePreview from '../../_bridges/RoutingResourcePreview/RoutingResourcePreview';

import Column from '../../layouts/Column/Column';
import ContentWithSidebar from '../../layouts/ContentWithSidebar/ContentWithSidebar';
import Divider from '../../layouts/GenericItems/Divider/Divider';
import InlineButton from '../../atoms/InlineButton/InlineButton';
import Typography, {VARIANT} from '../../atoms/Typography/Typography';

const ResourceTitle = () => (
  <ContentWithSidebar>
    <Column className="resource__title">
      <Typography variant={VARIANT.H1}>
        <FormattedMessage id="case_study.resources.title" />
      </Typography>
    </Column>
  </ContentWithSidebar>
);

const ResourcePreviewList = ({allowFirst = false, resources}) => (
  <>
    <Divider />
    <ResourceTitle />
    {resources.map((resource, index) => (
      <RoutingResourcePreview first={allowFirst && index === 0} key={index} resource={resource} />
    ))}
    <ContentWithSidebar>
      <Column className="resource__view-all">
        <FormattedNavLink name={RESOURCES}>
          <InlineButton withHover={true}>
            <Typography variant={VARIANT.BODY}>
              <FormattedMessage id="case_study.resources.view_all" />
            </Typography>
          </InlineButton>
        </FormattedNavLink>
      </Column>
    </ContentWithSidebar>
  </>
);
export default ResourcePreviewList;
