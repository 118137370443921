import React from 'react';
import PropTypes from 'prop-types';
import BREAKPOINTS from '../../_config/breakpoints';

import withSize from './../../../_packages/resize/ui/withSize';
import responsiveImagePropType from './../../_propTypes/responsiveImagePropType';

import {RATIO} from './../../atoms/Picture/Picture';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import CardImage from './../CardImage/CardImage';
import Content from './../Content/Content';

const Lin3rCard = ({content, image, title, windowWidth}) => (
  <CardImage
    image={image}
    ratio={RATIO.R_3_4}
    subtitle={<Content raw>{content}</Content>}
    title={<Typography variant={windowWidth < BREAKPOINTS.M ? VARIANT.BIG_BODY : VARIANT.H2}>{title}</Typography>}
  />
);

Lin3rCard.propTypes = {
  content: PropTypes.string.isRequired,
  image: responsiveImagePropType.isRequired,
  title: PropTypes.string.isRequired,
};

export default withSize(Lin3rCard);
