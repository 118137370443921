import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {FormattedMessage} from 'react-intl';

import BREAKPOINTS from './../../_config/breakpoints';
import withSize from './../../../_packages/resize/ui/withSize';
import ContactForm from './../../pages/BackendPage/Contact/ContactForm';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import Column from './../../layouts/Column/Column';
import FullRow from './../../layouts/FullRow/FullRow';
import Row from './../../layouts/Row/Row';

import './WhiteList.css';

const WhiteList = ({elements, hubspot, id, renderContainer, title, windowWidth}) => (
  <FullRow className="white-list" id={id ? id : null}>
    <Column className="white-list__column">
      <div className="white-list__wrapper">
        <Row className="white-list__title" scrollAnimation={false}>
          <Column>
            <Typography variant={VARIANT.H1}>{title}</Typography>
          </Column>
        </Row>

        {elements.map((element, index) => (
          <Row className="white-list__element" key={index} scrollAnimation={false}>
            <Column>
              <Typography variant={VARIANT.H3}>{element.title}</Typography>
              <div className="white-list__element-wrapper">
                {element.elements.map((item, itemIndex) => (
                  <>
                    {item.url ? (
                      renderContainer(
                        <div className="white-list__element-item" key={`${index}-${itemIndex}`}>
                          <Typography className="white-list__element-item-text">{item.text}</Typography>
                          {windowWidth >= BREAKPOINTS.M && (
                            <Typography variant={VARIANT.SMALL_BODY}>{item.cities.join(', ')}</Typography>
                          )}
                        </div>,
                        item.url,
                      )
                    ) : (
                      <div className="white-list__element-item" key={`${index}-${itemIndex}`}>
                        <Typography className="white-list__element-item-text">{item.text}</Typography>
                        {windowWidth >= BREAKPOINTS.M && (
                          <Typography variant={VARIANT.SMALL_BODY}>{item.cities.join(', ')}</Typography>
                        )}
                      </div>
                    )}
                  </>
                ))}
              </div>
            </Column>
          </Row>
        ))}
        {hubspot && hubspot.portalId && hubspot.formId && (
          <Row className="white-list__form" scrollAnimation={false}>
            <Column className="white-list__column">
              <Typography className="contact-form__title" variant={VARIANT.H3}>
                <FormattedMessage id={'contact.form.title'} />
              </Typography>
              <Typography className="contact-form__subtitle" variant={VARIANT.BODY}>
                <FormattedMessage id={'contact.form.subtitle'} />
              </Typography>
              <ContactForm formInfo={hubspot} />
            </Column>
          </Row>
        )}
      </div>
    </Column>
  </FullRow>
);

WhiteList.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      elements: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          url: PropTypes.string,
        }),
      ).isRequired,
    }),
  ).isRequired,
  id: PropTypes.string,
  renderContainer: PropTypes.func,
  title: PropTypes.string.isRequired,
};

WhiteList.defaultProps = {
  renderContainer: children => children,
};

export default compose(
  withSize,
  React.memo,
)(WhiteList);
