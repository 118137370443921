import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import errorReducer from './Error/ErrorReducer';
import landingFormReducer from './LandingForm/LandingFormReducer';
import apiReducer from './Api/ApiReducer';
import uiReducer from './Ui/UiReducer';
import i18nReducer from './I18n/I18nReducer';
import counterReducer from './Counter/CounterReducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    error: errorReducer,
    landingForm: landingFormReducer,
    api: apiReducer,
    ui: uiReducer,
    i18n: i18nReducer,
    counter: counterReducer,
  });
