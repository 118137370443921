import React from 'react';

import Slider from '../../compositions/Slider/Slider';
import FullRow from '../../layouts/FullRow/FullRow';
import Typography, {VARIANT} from '../../atoms/Typography/Typography';
import CardImage from '../CardImage/CardImage';
import {RATIO} from '../../atoms/Picture/Picture';
import InlineButton from '../../atoms/InlineButton/InlineButton';
import Column from '../../layouts/Column/Column';
import ContentWithSidebar from '../../layouts/ContentWithSidebar/ContentWithSidebar';

import './WhatsNew.css';

const SectionTitle = ({title}) => (
  <ContentWithSidebar>
    <Column className="whatsnew__title">
      <Typography variant={VARIANT.H1}>{title}</Typography>
    </Column>
  </ContentWithSidebar>
);

const Cta = ({title}) => (
  <InlineButton withHover={true}>
    <Typography variant={VARIANT.BODY}>→</Typography> {title}
  </InlineButton>
);

const WhatsNew = ({title, repeater: items}) => (
  <>
    <SectionTitle title={title} />
    <FullRow>
      <Column className="whatsnew__slider">
        <Slider slidesPerBreakpoint={{S: 1, M: 1.5, XXXXL: 1.5}}>
          {items.map((item, index) => (
            <a href={item.ctaUrl} target={item.ctaTarget}>
              <CardImage
                divider
                footer={
                  <a href={item.ctaUrl} target={item.ctaTarget}>
                    <Cta title={item.ctaText} />
                  </a>
                }
                image={item.image}
                key={index}
                ratio={RATIO.R_16_9}
                renderContainer={children => <div>{children}</div>}
                title={<Typography variant={VARIANT.H2}>{item.title}</Typography>}
                withHover
              />
            </a>
          ))}
        </Slider>
      </Column>
    </FullRow>
  </>
);

export default WhatsNew;
