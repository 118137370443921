import React from 'react';
import classNames from 'classnames';

import RoutingHero from './../../_bridges/RoutingHero/RoutingHero';

import GenericItems from './../../layouts/GenericItems/GenericItems';
import Main from './../../layouts/Main/Main';

import './Page.css';

const Page = ({content: page}) => (
  <Main
    className={classNames('page', {
      'page-landing': page.template === 'landing',
      'page-landing-donations-water': page.template === 'landing' && page.templateColor === 'blue',
    })}
  >
    <RoutingHero
      className={classNames('page__hero', {
        'page__hero--donations': page.template === 'landing',
        'page__hero--donations-water': page.template === 'landing' && page.templateColor === 'blue',
      })}
      hero={page.hero}
      heroWithLogo={page.template === 'landing'}
    />

    {page.items && <GenericItems items={page.items} />}
  </Main>
);

export default React.memo(Page);
