import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import responsiveImagePropType from './../../_propTypes/responsiveImagePropType';

import Picture, {RATIO} from './../../atoms/Picture/Picture';
import {VARIANT} from './../../atoms/Typography/Typography';

import Content from './../Content/Content';

import './ImageFull.css';

const ImageFull = ({id, image, ratio}) => (
  <figure
    className={classNames('image-full', {'image-full--with-caption': Boolean(image.caption)})}
    id={id ? id : null}
  >
    <Picture
      alt={image.alt}
      className="image-full__picture"
      cover
      ratio={ratio}
      src={image.src.large}
      srcSet={[
        {
          src: image.src.medium,
          maxWidth: 639,
        },
      ]}
    />
    {image.caption && (
      <Content className="image-full__caption" raw tag="figcaption" variant={VARIANT.BODY}>
        {image.caption}
      </Content>
    )}
  </figure>
);

ImageFull.propTypes = {
  id: PropTypes.string,
  image: responsiveImagePropType.isRequired,
  ratio: PropTypes.oneOf(Object.values(RATIO)),
};

export default React.memo(ImageFull);
