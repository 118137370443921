import {LOCATION_CHANGE} from 'connected-react-router';

export const MENU_OPEN = '[UI] Menu open';
export const MENU_CLOSE = '[UI] Menu close';

const initialState = {
  menuOpened: false,
  ssrWindowWidth: null, // This value will be set in the middleware as the preloadedState
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MENU_OPEN: {
      return {
        ...state,
        menuOpened: true,
      };
    }

    case LOCATION_CHANGE:
    case MENU_CLOSE: {
      return {
        ...state,
        menuOpened: initialState.menuOpened,
      };
    }

    default: {
      return state;
    }
  }
};
