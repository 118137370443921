import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ScrollAnimation from './../../_behaviours/ScrollAnimation';
import ConditionalWrap from './../../_util/ConditionalWrap';

import './Row.css';

const Row = ({children, className, scrollAnimation, tag: RowTag, ...rest}) => (
  <ConditionalWrap condition={scrollAnimation} wrap={content => <ScrollAnimation>{content}</ScrollAnimation>}>
    <RowTag className={classNames('row', className)} {...rest}>
      {children}
    </RowTag>
  </ConditionalWrap>
);

Row.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  scrollAnimation: PropTypes.bool,
  tag: PropTypes.string,
};

Row.defaultProps = {
  scrollAnimation: true,
  tag: 'div',
};

export default React.memo(Row);
