import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import Section from './../Section/Section';

import Grid from './../../layouts/Grid/Grid';

import './StatsSummary.css';

const StatsSummary = ({className, stats, title, ...rest}) => (
  <Section className={classNames('stats-summary', className)} title={title} {...rest}>
    <Grid columnsPerBreakpoint={{S: 2}} itemClassName="stats-summary__item" scrollAnimation={false}>
      {stats.map((item, index) => (
        <React.Fragment key={index}>
          <Typography className="stats-summary__item-title" tag="div" variant={VARIANT.H1}>
            {item.value}
          </Typography>
          <Typography>{item.text}</Typography>
        </React.Fragment>
      ))}
    </Grid>
  </Section>
);

StatsSummary.propTypes = {
  className: PropTypes.string,
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.node,
};

export default StatsSummary;
