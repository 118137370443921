import caseStudies from './caseStudies/routes';
import people from './people/routes';
import jobPositions from './jobPositions/routes';
import resources from './resources/routes';

export const HOME = 'home';

const routes = {
  ...caseStudies,
  ...people,
  ...jobPositions,
  ...resources,
};

export default {
  [HOME]: {
    es: '/',
    en: '/',
    fr: '/',
  },
  ...routes,
};
