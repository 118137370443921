import {MENU_OPEN, MENU_CLOSE} from './UiReducer';

export const openMenu = () => async dispatch => {
  dispatch({
    type: MENU_OPEN,
  });
};

export const closeMenu = () => async dispatch => {
  dispatch({
    type: MENU_CLOSE,
  });
};
