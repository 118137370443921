import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Transition, animated} from 'react-spring/renderprops.cjs.js';
import get from 'lodash.get';

import ScrollToTop from './../../_behaviours/ScrollToTop';
import {extractSeoOpengraph, extractSeoTwitter} from '../../_util/seoExtract';
import {TEMPLATE, PAGE_TEMPLATE} from '../../../config/cmsSettings';
import isSSR from './../../../config/isSSR';
import i18n from './../../../i18n';
import {defaultUrl} from '../../seo/url';
import Loader from './../../atoms/Loader/Loader';
import Helmet from './../../seo/Helmet';

import CaseStudies from './../../templates/CaseStudies/CaseStudies';
import CaseStudy from './../../templates/CaseStudy/CaseStudy';
import Resources from './../../templates/Resources/Resources';
import Resource from './../../templates/Resource/Resource';
import Home from './../../templates/Homepage/Homepage';
import JobPosition from './../../templates/JobPosition/JobPosition';
import Page from './../../templates/Page/Page';
import People from './../../templates/People/People';

import NewsletterPopup from '../../compositions/NewsletterPopup/NewsletterPopup';

import Contact from './Contact/Contact';

const componentForTemplateKey = {
  [TEMPLATE.CASE_STUDIES]: CaseStudies,
  [TEMPLATE.CASE_STUDY]: CaseStudy,
  [TEMPLATE.RESOURCES]: Resources,
  [TEMPLATE.RESOURCE]: Resource,
  [TEMPLATE.JOB_POSITION]: JobPosition,
  [TEMPLATE.PEOPLE]: People,
  [TEMPLATE.PAGE]: {
    [PAGE_TEMPLATE.HOME]: Home,
    [PAGE_TEMPLATE.GENERIC]: Page,
    [PAGE_TEMPLATE.WHITE]: Page,
    [PAGE_TEMPLATE.DEFAULT]: Page,
    [PAGE_TEMPLATE.LANDING]: Page,
  },
};

const generateHrefLangs = translatedSlugs => {
  const hrefBase = defaultUrl();
  const hrefLangs = [];
  i18n.locales.forEach(locale => {
    const slug = get(translatedSlugs, locale, '');

    if (slug === null) {
      return;
    }

    const href = `${hrefBase}${i18n.prependLocale(locale, slug)}`;

    if (locale === i18n.defaultLocale) {
      hrefLangs.push({href: href, hrefLang: 'x-default', rel: 'alternate'});
    }

    hrefLangs.push({href: href, hrefLang: locale, rel: 'alternate'});
  });

  return hrefLangs;
};

const BackendPageContent = ({isIE, page, translatedSlugs}) => {
  useEffect(() => {
    if (!isSSR() && window.objectFitPolyfill) {
      setTimeout(window.objectFitPolyfill, 1000);
    }
  }, []);
  const Template =
    componentForTemplateKey[page.resource.template][page.resource.content.template] ||
    componentForTemplateKey[page.resource.template];
  const {content, pagination} = page.resource;
  const seo = page.resource.seo || content.yoast || {};
  const seoOG = extractSeoOpengraph(seo);
  const seoTW = extractSeoTwitter(seo);
  const settings = page.resource.settings;
  /* Quick hack: pass settings along the content to avoid changing all template components */
  content.settings = settings;

  return (
    <>
      <ScrollToTop />
      <Helmet
        canonical={seo.canonical}
        description={seo.metadesc || seo.description}
        fullTitle={seo.title}
        image={seoOG.image}
        links={generateHrefLangs(translatedSlugs)}
        opengraph={seoOG}
        twitter={seoTW}
      />
      <Template content={content} isIE={isIE} pagination={pagination} />
      <Contact cta={content.cta} />
      {page.resource.content.template !== PAGE_TEMPLATE.HOME && settings.popupHubspot.formId && (
        <NewsletterPopup formId={settings.popupHubspot.formId} portalId={settings.popupHubspot.portalId} />
      )}
    </>
  );
};

const BackendPage = ({isIE, page, translatedSlugs}) => {
  const loading = !page || !page.resource;

  if (loading) {
    return <Loader />;
  }
  const backendPageContent = <BackendPageContent isIE={isIE} page={page} translatedSlugs={translatedSlugs} />;

  if (isIE) {
    return backendPageContent;
  }

  return isSSR() ? (
    <div style={{opacity: 0}}>{backendPageContent}</div>
  ) : (
    <Transition
      enter={{opacity: 1, transform: 'translate3d(0, 0px, 0)'}}
      from={{opacity: 0.1, transform: 'translate3d(0, 100px, 0)'}}
    >
      {() => style => {
        const filteredStyle = {
          opacity: style.opacity,
          ...(style.transform !== 'translate3d(0, 0px, 0)' && {transform: style.transform}),
        };

        return <animated.div style={filteredStyle}>{backendPageContent}</animated.div>;
      }}
    </Transition>
  );
};

const mapStateToProps = state => ({
  page: get(state, 'api.backendPage'),
  isIE: state.ui.isIE,
  translatedSlugs: get(state, 'api.backendPage.resource.translatedSlugs', i18n.defaultTranslatedRoutes),
});

export default connect(mapStateToProps)(React.memo(BackendPage));
