import React from 'react';
import {FormattedNavLink} from '@foes/react-i18n-routing';
import {FormattedMessage} from 'react-intl';

import {CASE_STUDY} from './../../../../routing/caseStudies/routes';

import {VARIANT} from './../../../atoms/Typography/Typography';

import ItemPreview from '../../../components/ItemPreview/ItemPreview';
import Section from './../../../components/Section/Section';

import FullRow from './../../FullRow/FullRow';
import Grid from './../../Grid/Grid';

import './Relation.css';

const getRenderContainerByType = element => {
  switch (element.type) {
    case 'case_study': {
      return children => (
        <FormattedNavLink name={CASE_STUDY} params={{slug: element.slug}}>
          {children}
        </FormattedNavLink>
      );
    }

    default: {
      return children => children;
    }
  }
};

const Relation = ({ctaText, elements, id, label, title}) => (
  <Section
    className="relation"
    contentColumnTag="div"
    contentTag="div"
    id={id ? id : null}
    label={label}
    title={title}
    titleVariant={VARIANT.H1}
  >
    <Grid
      className="relation__grid"
      columnsPerBreakpoint={{S: 1, M: 2}}
      itemClassName="relation__grid-item"
      scrollAnimation={false}
      tag={FullRow}
    >
      {elements.map((element, subIndex) => (
        <ItemPreview
          footerText={ctaText || <FormattedMessage id="relation.view_more" />}
          image={element.image}
          key={subIndex}
          renderContainer={getRenderContainerByType(element)}
          title={element.title}
          withHover
        />
      ))}
    </Grid>
  </Section>
);

export default React.memo(Relation);
