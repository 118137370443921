import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {FormattedMessage} from 'react-intl';

import withSize from '../../../_packages/resize/ui/withSize';

import Typography, {VARIANT} from '../../atoms/Typography/Typography';
import InlineButton from '../../atoms/InlineButton/InlineButton';
import ExternalLink from '../../atoms/ExternalLink/ExternalLink';
import TeamtailorForm from './TeamtailorForm';

import Column from '../../layouts/Column/Column';
import FullRow from '../../layouts/FullRow/FullRow';
import Row from '../../layouts/Row/Row';

import './Teamtailor.css';

const Teamtailor = ({id, title, subtitle, link}) => (
  <FullRow className="teamtailor" id={id ? id : null}>
    <Column className="teamtailor__column">
      <div className="teamtailor__wrapper">
        <Row className="teamtailor__title" scrollAnimation={false}>
          <Column>{title && <Typography variant={VARIANT.H1}>{title}</Typography>}</Column>
        </Row>
        {link && link.text && link.url && (
          <Row className="teamtailor__form" scrollAnimation={false}>
            <Column className="">
              {subtitle && <Typography variant={VARIANT.H3}>{subtitle}</Typography>}
              <TeamtailorForm />
              {link && link.title && (
                <Typography className="contact-form__title" variant={VARIANT.H3}>
                  <FormattedMessage id={link.title} />
                </Typography>
              )}
              {link && link.subtitle && (
                <Typography className="contact-form__subtitle" variant={VARIANT.BODY}>
                  <FormattedMessage id={link.subtitle} />
                </Typography>
              )}
              <div className="white-list__content-teamtailor">
                <div>
                  <ExternalLink className="" href={link.url}>
                    <InlineButton withHover={true}>
                      <FormattedMessage id={link.text} />
                    </InlineButton>
                  </ExternalLink>
                </div>
              </div>
            </Column>
          </Row>
        )}
      </div>
    </Column>
  </FullRow>
);

Teamtailor.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      elements: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          url: PropTypes.string,
        }),
      ).isRequired,
    }),
  ).isRequired,
  id: PropTypes.string,
  renderContainer: PropTypes.func,
  title: PropTypes.string.isRequired,
};

Teamtailor.defaultProps = {
  renderContainer: children => children,
};

export default compose(
  withSize,
  React.memo,
)(Teamtailor);
