import React, {Component} from 'react';
import {compose} from 'redux';
import Column from '../../layouts/Column/Column';
import {withI18nRouting} from '@foes/react-i18n-routing';
import Row from '../../layouts/Row/Row';
import './CookieList.css';

export class CookieList extends Component {
  componentDidMount() {
    const script = document.createElement('script');

    script.src = 'https://consent.cookiebot.com/1cb7bd07-377a-4df7-8097-e2e9b5c7454d/cd.js';
    script.id = 'cookieDeclaration';
    script.type = 'text/javascript';
    script.dataset.culture = this.props.i18nRouting.locale;
    script.async = true;

    document.getElementById('cookiebot').appendChild(script);
  }
  render() {
    return (
      <Row className="form-generic__content-bottom cookieList">
        <Column className="cookieList__content-html">
          <div id="cookiebot"></div>
        </Column>
      </Row>
    );
  }
}

export default compose(
  withI18nRouting,
  React.memo,
)(CookieList);
