import React from 'react';
import PropTypes from 'prop-types';

import Lin3rCard from './../../components/Lin3rCard/Lin3rCard';

import FullRow from './../../layouts/FullRow/FullRow';
import Grid from './../../layouts/Grid/Grid';

import './Lin3rs.css';

const Lin3rs = ({lin3rs}) => (
  <Grid className="lin3rs" columnsPerBreakpoint={{S: 2, M: 3, XL: 4}} itemClassName="lin3rs__item" tag={FullRow}>
    {lin3rs.map((lin3r, index) => (
      <Lin3rCard key={index} {...lin3r} />
    ))}
  </Grid>
);

Lin3rs.propTypes = {
  lin3rs: PropTypes.arrayOf(PropTypes.object),
};

export default Lin3rs;
