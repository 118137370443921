import get from 'lodash.get';

export const API_FETCHING = '[API] Resource fetching';
export const API_RECEIVED = '[API] Resource received';
export const API_FAILED = '[API] Resource request failed';
export const API_CANCELED = '[API] Resource request canceled';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case API_RECEIVED: {
      return {
        ...state,
        [action.resourceType]: {state: action.type, resource: action.resource},
      };
    }
    case API_FETCHING:
    case API_FAILED: {
      return {
        ...state,
        [action.resourceType]: {state: action.type, resource: null},
      };
    }
    case API_CANCELED:
    default: {
      return state;
    }
  }
};

const transformSocialLinks = socialLinks =>
  socialLinks &&
  socialLinks.map(item => ({
    href: item.url,
    name: item.fullText,
    shortName: item.text,
  }));

export const getFooterMenu = state => {
  const menus = get(state, 'api.backendContext.resource.menus');
  if (!menus) {
    return null;
  }

  const footerMenu = menus.find(item => item.position === 'footer-menu');
  const {address: {elements: addresses} = {}, social: {social: socialLinks} = {}} = get(footerMenu, 'options', {});

  return {
    addresses: addresses,
    items: get(footerMenu, 'items', []),
    socialLinks: transformSocialLinks(socialLinks),
  };
};

export const getHeaderMenu = state => {
  const menus = get(state, 'api.backendContext.resource.menus');
  if (!menus) {
    return null;
  }

  const headerMenu = menus.find(item => item.position === 'main-menu');

  return {
    items: get(headerMenu, 'items', []),
  };
};

export const getSettings = state => {
  const settings = get(state, 'api.backendPage.resource.settings');
  if (!settings) {
    return null;
  }

  return {
    headerCta: get(settings, 'headerCta', {}),
    footerCta: get(settings, 'footerCta', {}),
  };
};
