import React from 'react';
import {FormattedNavLink} from '@foes/react-i18n-routing';

import {RESOURCE} from '../../../routing/resources/routes';

import resourcePreviewPropType from '../../_propTypes/resourcePreviewPropType';
import ResourcePreview from '../../components/ResourcePreview/ResourcePreview';
import Column from '../../layouts/Column/Column';
import ContentWithSidebar from '../../layouts/ContentWithSidebar/ContentWithSidebar';
import Typography from '../../atoms/Typography/Typography';

import './RoutingResourcePreview.css';

const RoutingResourcePreview = ({resource, first, ...rest}) => (
  <ContentWithSidebar
    className={`resource-preview-container ${first ? 'resource-preview-container--first' : ''}`}
    left={
      <Column className="resource-preview-title">
        {resource.resource.resourceType && <Typography>{resource.resource.resourceType}</Typography>}
      </Column>
    }
  >
    <Column>
      <ResourcePreview
        renderContainer={children => (
          <FormattedNavLink name={RESOURCE} params={{slug: resource.slug}}>
            {children}
          </FormattedNavLink>
        )}
        resource={resource}
        {...rest}
      />
    </Column>
  </ContentWithSidebar>
);

RoutingResourcePreview.propTypes = {
  first: resourcePreviewPropType.bool,
  resource: resourcePreviewPropType.isRequired,
};

RoutingResourcePreview.defaultProps = {
  first: false,
};

export default RoutingResourcePreview;
