import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import isSSR from './../../../config/isSSR';

import {ReactComponent as ZoomIn} from './../../svg/iconography/zoom-in.svg';
import {ReactComponent as ZoomOut} from './../../svg/iconography/zoom-out.svg';
import {ReactComponent as Close} from './../../svg/iconography/close.svg';
import {ReactComponent as Dropdown} from './../../svg/iconography/dropdown.svg';
import {ReactComponent as ArrowRight} from './../../svg/iconography/arrow-right.svg';
import {ReactComponent as Fullscreen} from './../../svg/iconography/fullscreen.svg';
import {ReactComponent as FullscreenClose} from './../../svg/iconography/fullscreen-close.svg';
import {ReactComponent as Menu} from './../../svg/iconography/menu.svg';
import {ReactComponent as LIN3S} from './../../svg/iconography/lin3s.svg';
import {ReactComponent as ParallelLines} from './../../svg/iconography/parallel-lines.svg';
import {ReactComponent as ParallelLinesMobile} from './../../svg/iconography/parallel-lines-mobile.svg';
import {ReactComponent as ParallelLines2} from './../../svg/iconography/parallel-lines-2.svg';
import {ReactComponent as ParallelLines2Mobile} from './../../svg/iconography/parallel-lines-2-mobile.svg';
import {ReactComponent as ParallelLines3} from './../../svg/iconography/parallel-lines-3.svg';
import {ReactComponent as ParallelLines3Mobile} from './../../svg/iconography/parallel-lines-3-mobile.svg';
import {ReactComponent as ParallelLines4} from './../../svg/iconography/parallel-lines-4.svg';
import {ReactComponent as ParallelLines4Mobile} from './../../svg/iconography/parallel-lines-4-mobile.svg';
import {ReactComponent as SmallDrop} from './../../svg/iconography/small_drop.svg';
import {ReactComponent as BigDrop} from './../../svg/iconography/big_drop.svg';
import {ReactComponent as Facebook} from './../../svg/iconography/facebook.svg';
import {ReactComponent as Twitter} from './../../svg/iconography/twitter.svg';
import {ReactComponent as Instagram} from './../../svg/iconography/instagram.svg';
import {ReactComponent as LinkedIn} from './../../svg/iconography/linkedin.svg';
import {ReactComponent as Youtube} from './../../svg/iconography/youtube.svg';
import {ReactComponent as ToolTip} from './../../svg/iconography/tooltip.svg';

import './Iconography.css';

const ICON = {
  ZOOM_IN: 'ZOOM_IN',
  ZOOM_OUT: 'ZOOM_OUT',
  CLOSE: 'CLOSE',
  DROPDOWN: 'DROPDOWN',
  ARROW_RIGHT: 'ARROW_RIGHT',
  FULLSCREEN: 'FULLSCREEN',
  FULLSCREEN_CLOSE: 'FULLSCREEN_CLOSE',
  MENU: 'MENU',
  LIN3S: 'LIN3S',
  PARALLEL_LINES: 'PARALLEL_LINES',
  PARALLEL_LINES_MOBILE: 'PARALLEL_LINES_MOBILE',
  PARALLEL_LINES2: 'PARALLEL_LINES2',
  PARALLEL_LINES2_MOBILE: 'PARALLEL_LINES2_MOBILE',
  PARALLEL_LINES3: 'PARALLEL_LINES3',
  PARALLEL_LINES3_MOBILE: 'PARALLEL_LINES3_MOBILE',
  PARALLEL_LINES4: 'PARALLEL_LINES4',
  PARALLEL_LINES4_MOBILE: 'PARALLEL_LINES4_MOBILE',
  SMALL_DROP: 'SMALL_DROP',
  BIG_DROP: 'BIG_DROP',
  FACEBOOK: 'FACEBOOK',
  TWITTER: 'TWITTER',
  INSTAGRAM: 'INSTAGRAM',
  LINKEDIN: 'LINKEDIN',
  YOUTUBE: 'YOUTUBE',
  TOOLTIP: 'TOOLTIP',
};

const SIZE = {
  DEFAULT: 'DEFAULT',
  BIG: 'BIG',
};

const ICON_MAP = {
  [ICON.ZOOM_IN]: ZoomIn,
  [ICON.ZOOM_OUT]: ZoomOut,
  [ICON.CLOSE]: Close,
  [ICON.DROPDOWN]: Dropdown,
  [ICON.ARROW_RIGHT]: ArrowRight,
  [ICON.FULLSCREEN]: Fullscreen,
  [ICON.FULLSCREEN_CLOSE]: FullscreenClose,
  [ICON.MENU]: Menu,
  [ICON.LIN3S]: LIN3S,
  [ICON.PARALLEL_LINES]: ParallelLines,
  [ICON.PARALLEL_LINES_MOBILE]: ParallelLinesMobile,
  [ICON.PARALLEL_LINES2]: ParallelLines2,
  [ICON.PARALLEL_LINES2_MOBILE]: ParallelLines2Mobile,
  [ICON.PARALLEL_LINES3]: ParallelLines3,
  [ICON.PARALLEL_LINES3_MOBILE]: ParallelLines3Mobile,
  [ICON.PARALLEL_LINES4]: ParallelLines4,
  [ICON.PARALLEL_LINES4_MOBILE]: ParallelLines4Mobile,
  [ICON.SMALL_DROP]: SmallDrop,
  [ICON.BIG_DROP]: BigDrop,
  [ICON.FACEBOOK]: Facebook,
  [ICON.TWITTER]: Twitter,
  [ICON.INSTAGRAM]: Instagram,
  [ICON.LINKEDIN]: LinkedIn,
  [ICON.YOUTUBE]: Youtube,
  [ICON.TOOLTIP]: ToolTip,
};

const Iconography = ({className, icon, size, ...rest}) => {
  const IconSvg = ICON_MAP[icon];
  if (!isSSR() && !IconSvg) {
    throw new TypeError(`Provided {icon: ${icon} combination does not match any available Iconography`);
  }

  return (
    <span
      className={classNames(
        'iconography',
        {
          'iconography--big': size === SIZE.BIG,
          'iconography--small': size === SIZE.SMALL,
        },
        className,
      )}
    >
      {IconSvg && <IconSvg {...rest} />}
    </span>
  );
};

Iconography.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(Object.values(ICON)).isRequired,
  size: PropTypes.oneOf(Object.values(SIZE)).isRequired,
};

Iconography.defaultProps = {
  size: SIZE.DEFAULT,
};

export {ICON, SIZE};
export default React.memo(Iconography);
