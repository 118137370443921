import React from 'react';
import classNames from 'classnames';

import {ICON} from './../../atoms/Iconography/Iconography';

import BusinessBranches from './../../compositions/BusinessBranches/BusinessBranches';
import CmsLink from './../../components/CmsLink/CmsLink';
import ImageFull from './../../components/ImageFull/ImageFull';
import ListGrid from './../../components/ListGrid/ListGrid';
import Subtitle from './../../components/Subtitle/Subtitle';
import WhiteList from './../../components/WhiteList/WhiteList';
import CmsContactForm from './../../components/CmsContactForm/CmsContactForm';
import ImageContent from './../../components/ImageContent/ImageContent';
import ListImageContent from './../../components/ListImageContent/ListImageContent';
import CounterComponent from './../../components/CounterComponent/CounterComponent';
import LocationComponent from './../../components/LocationComponent/LocationComponent';
import JoinWeShare from './../../components/JoinWeShare/JoinWeShare';
import CookieList from '../../components/CookieList/CookieList';
import DoNotMiss from '../../components/DoNotMiss/DoNotMiss';
import WhatsNew from '../../components/WhatsNew/WhatsNew';
import Teamtailor from '../../components/TeamtailorComponent/TeamtailorComponent';

import CustomContent from './../../compositions/CustomContent/CustomContent';
import LogoRepeater from './../../compositions/LogoRepeater/LogoRepeater';
import StatsSummaries from './../../compositions/StatsSummaries/StatsSummaries';

import Divider from './Divider/Divider';
import Gallery from './Gallery/Gallery';
import Relation from './Relation/Relation';

import './GenericItems.css';

const BusinessBranchesIconMap = {
  pattern_1: ICON.PARALLEL_LINES,
  pattern_2: ICON.PARALLEL_LINES2,
  pattern_3: ICON.PARALLEL_LINES3,
  pattern_4: ICON.PARALLEL_LINES4,
};

const mapColumnsToBusinessBranches = elements =>
  elements.map(({pattern, ...rest}) => ({
    ...rest,
    type: BusinessBranchesIconMap[pattern] || ICON.PARALLEL_LINES,
  }));

const PageItemsToComponent = ({items}) =>
  items &&
  items.map(({type, ...content}, index) => {
    if (type === 'title_text') {
      return (
        <ListGrid
          key={`generic-item-${index}`}
          renderContainer={(children, extrainfo, url) => (
            <CmsLink extrainfo={extrainfo} slug={url}>
              {children}
            </CmsLink>
          )}
          {...content}
        />
      );
    }

    if (type === 'logo_repeater') {
      return (
        <div className={classNames('logorepeater')}>
          <LogoRepeater key={`generic-item-${index}`} {...content} />
        </div>
      );
    }

    if (type === 'image_full') {
      return <ImageFull key={`generic-item-${index}`} {...content} />;
    }

    if (type === 'divider') {
      return <Divider key={`generic-item-${index}`} />;
    }

    if (type === 'relation') {
      return <Relation key={`generic-item-${index}`} {...content} />;
    }

    if (type === 'subtitle') {
      return (
        <Subtitle
          key={`generic-item-${index}`}
          renderContainer={(children, extrainfo, url) => (
            <CmsLink extrainfo={extrainfo} slug={url}>
              {children}
            </CmsLink>
          )}
          {...content}
        />
      );
    }

    if (type === 'numbers_block') {
      return <StatsSummaries key={index} {...content} />;
    }

    if (type === 'columns') {
      return (
        <BusinessBranches
          businessBranches={mapColumnsToBusinessBranches(content.elements)}
          id={content.id}
          key={`generic-item-${index}`}
        />
      );
    }

    if (type === 'gallery') {
      return <Gallery key={`generic-item-${index}`} {...content} />;
    }

    if (type === 'title_list_white') {
      return (
        <WhiteList
          key={`generic-item-${index}`}
          renderContainer={(children, url) =>
            url ? (
              <CmsLink key={url} slug={url}>
                {children}
              </CmsLink>
            ) : (
              children
            )
          }
          {...content}
        />
      );
    }

    if (type === 'custom_content') {
      return (
        <CustomContent
          key={`generic-item-${index}`}
          renderContainer={(children, url) => <CmsLink slug={url}>{children}</CmsLink>}
          {...content}
        />
      );
    }

    if (type === 'form') {
      return <CmsContactForm key={index} {...content} />;
    }

    if (type === 'image_content') {
      return (
        <ImageContent
          key={`generic-item-${index}`}
          renderContainer={(children, url, key) =>
            url ? (
              <CmsLink key={key} slug={url}>
                {children}
              </CmsLink>
            ) : (
              children
            )
          }
          {...content}
        />
      );
    }

    if (type === 'list_image_content') {
      return <ListImageContent key={`generic-item-${index}`} {...content} />;
    }

    if (type === 'location') {
      return <LocationComponent key={`generic-item-${index}`} {...content} />;
    }

    if (type === 'counter') {
      return <CounterComponent key={`generic-item-${index}`} {...content} />;
    }

    if (type === 'share') {
      return <JoinWeShare key={`generic-item-${index}`} {...content} />;
    }

    if (type === 'cookielist') {
      return <CookieList key={`generic-item-${index}`} {...content} />;
    }

    if (type === 'donotmiss') {
      return (
        <div className={classNames('donotmiss')}>
          <DoNotMiss key={`generic-item-${index}`} {...content} />
        </div>
      );
    }

    if (type === 'whatsnew') {
      return (
        <div className={classNames('whatsnew')}>
          <WhatsNew key={`generic-item-${index}`} {...content} />
        </div>
      );
    }

    if (type === 'teamtailor') {
      return (
        <Teamtailor
          key={`generic-item-${index}`}
          renderContainer={(children, url) =>
            url ? (
              <CmsLink key={url} slug={url}>
                {children}
              </CmsLink>
            ) : (
              children
            )
          }
          {...content}
        />
      );
    }

    return null;
  });

const GenericItems = ({className, items, ...rest}) => (
  <div className={classNames('generic-items', className)} {...rest}>
    {items && <PageItemsToComponent items={items} />}
  </div>
);

export default GenericItems;
