import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Typography.css';

const VARIANT = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H3_REGULAR: 'h3-regular',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  BIG_BODY: 'big-body',
  BODY: 'body',
  SMALL_BODY: 'small-body',
  BIG_H1: 'big-h1',
};

const Typography = ({className, tag: TypographyTag, children, variant, ...rest}) => (
  <TypographyTag className={classNames('typography', `typography--${variant}`, className)} {...rest}>
    {children}
  </TypographyTag>
);

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  variant: PropTypes.oneOf(Object.values(VARIANT)),
};

Typography.defaultProps = {
  tag: 'span',
  variant: VARIANT.BODY,
};

export {VARIANT};

export default React.memo(Typography);
