const requestQueueForUrl = {};

/**
 * This method will cancel any pending request if it's associated key do not match the provided key.
 * You can also provide a keyFilter function to prevent some requests (key associated) being cancelled.
 */

const cancelNonMatchingKeyRequests = ({request, key = '', keyFilter = () => false} = {}) => {
  // Get non-matching keys for the given key
  const nonMatchingKeys = Object.keys(requestQueueForUrl).filter(keyFilter);

  // Cancel and remove non-matching-key's requests
  nonMatchingKeys.forEach(nonMatchingKey => {
    requestQueueForUrl[nonMatchingKey].forEach(nonMatchingRequest => {
      if (typeof nonMatchingRequest.cancel === 'function') {
        nonMatchingRequest.cancel({message: 'Request cancelled due to non-matching key', cancelled: true});
      }

      // Remove the request from the queue
      const requestIndex = requestQueueForUrl[nonMatchingKey].indexOf(nonMatchingRequest);
      requestQueueForUrl[nonMatchingKey].splice(requestIndex, 1);
    });
  });

  if (requestQueueForUrl[key]) {
    requestQueueForUrl[key].push(request);
  } else {
    requestQueueForUrl[key] = [request];
  }

  // Delete the key-associated array and GC if they are no requests left for the key.
  Object.keys(requestQueueForUrl).forEach(reqKey => {
    if (requestQueueForUrl[reqKey].length === 0) {
      // eslint-disable-next-line prefer-reflect
      delete requestQueueForUrl[reqKey];
    }
  });
};

export default {cancelNonMatchingKeyRequests};
