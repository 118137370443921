import React from 'react';
import PropTypes from 'prop-types';

import InlineButton from './../../atoms/InlineButton/InlineButton';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Picture from './../../atoms/Picture/Picture';

import Content from './../../components/Content/Content';

import Column from './../../layouts/Column/Column';
import FullRow from './../../layouts/FullRow/FullRow';
import Grid from './../../layouts/Grid/Grid';
import Row from './../../layouts/Row/Row';

import './CustomContent.css';

const shouldShowCta = element => element.cta && element.cta.text && element.cta.url;

const shouldImageAndTitleBeLink = element =>
  element.cta.imageAndTitle && element.cta && element.cta.text && element.cta.url;

const CustomContentCard = ({element, renderContainer}) => (
  <div className="custom-content__card">
    {element.image && (
      <>
        {shouldImageAndTitleBeLink(element) ? (
          renderContainer(
            <Picture
              alt={element.image.alt}
              className="hero__picture"
              cover
              imageClassName="hero__picture-image"
              pictureClassName="hero__picture-picture"
              src={element.image.src.large || element.image.src.original}
              srcSet={[
                {
                  src: element.image.src.medium,
                  maxWidth: 639,
                },
              ]}
            />,
            element.cta.url,
          )
        ) : (
          <Picture
            alt={element.image.alt}
            className="hero__picture"
            cover
            imageClassName="hero__picture-image"
            pictureClassName="hero__picture-picture"
            src={element.image.src.large || element.image.src.original}
            srcSet={[
              {
                src: element.image.src.medium,
                maxWidth: 639,
              },
            ]}
          />
        )}
      </>
    )}
    <Typography variant={VARIANT.BIG_BODY}>{element.preTitle}</Typography>
    {shouldImageAndTitleBeLink(element) ? (
      renderContainer(
        <Typography className="custom-content__card-title" tag="div" variant={VARIANT.BIG_BODY}>
          {element.title}
        </Typography>,
        element.cta.url,
      )
    ) : (
      <Typography className="custom-content__card-title" tag="div" variant={VARIANT.BIG_BODY}>
        {element.title}
      </Typography>
    )}

    {element.excerpt && (
      <Content className="custom-content__card-content" raw>
        {element.excerpt}
      </Content>
    )}
    {shouldShowCta(element) && <>{renderContainer(<InlineButton>{element.cta.text}</InlineButton>, element.cta.url)}</>}
  </div>
);

const CustomContent = ({elements, id, image, renderContainer, title, negative}) => (
  <FullRow className={`custom-content ${negative ? 'custom-content--inverted' : ''}`} id={id ? id : null}>
    {image && (
      <div className="custom-content__picture" scrollAnimation={false}>
        <Column>
          <Picture
            alt={image.alt}
            className="hero__picture"
            cover
            imageClassName="hero__picture-image"
            pictureClassName="hero__picture-picture"
            src={image.src.large || image.src.original}
            srcSet={[
              {
                src: image.src.medium,
                maxWidth: 639,
              },
            ]}
          />
          <div className="locations">
            <Row>
              <div className="custom-content__title" scrollAnimation={false}>
                <Column>
                  <Typography variant={VARIANT.H1}>{title}</Typography>
                </Column>
              </div>
              <Grid
                className="custom-content__grid"
                columnsPerBreakpoint={{S: 1, M: 2}}
                itemClassName="custom-content__grid-item"
                scrollAnimation={false}
                tag="div"
              >
                {elements.map((element, index) => (
                  <CustomContentCard element={element} key={index} renderContainer={renderContainer} />
                ))}
              </Grid>
            </Row>
          </div>
        </Column>
      </div>
    )}
  </FullRow>
);

CustomContent.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      cta: PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      preTitle: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  id: PropTypes.string,
  image: PropTypes.object,
  negative: PropTypes.bool,
  renderContainer: PropTypes.func,
  title: PropTypes.string.isRequired,
};

CustomContent.defaultProps = {
  negative: false,
  renderContainer: children => children,
};

export default CustomContent;
