import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ExternalLink from './../../atoms/ExternalLink/ExternalLink';
import InlineButton from './../../atoms/InlineButton/InlineButton';
import {VARIANT} from './../../atoms/Typography/Typography';

import './SocialLinks.css';

const SocialLinks = ({className, isFooter, linkClassName, links, short}) => (
  <ul className={classNames('social-links', className)}>
    {links.map((link, index) => (
      <li className="social-links__item" key={index}>
        <ExternalLink
          aria-label={link.name}
          className={classNames('social-links__link', linkClassName)}
          href={link.href}
        >
          <InlineButton variant={isFooter && VARIANT.BODY}>{short ? link.shortName : link.name}</InlineButton>
        </ExternalLink>
      </li>
    ))}
  </ul>
);

SocialLinks.propTypes = {
  className: PropTypes.string,
  linkClassName: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      shortName: PropTypes.string,
    }),
  ).isRequired,
  short: PropTypes.bool,
};

SocialLinks.defaultProps = {
  short: false,
};

export default SocialLinks;
