import React from 'react';

import RoutingHero from './../../_bridges/RoutingHero/RoutingHero';

import Lin3rs from './../../compositions/Lin3rs/Lin3rs';

import Main from './../../layouts/Main/Main';

import './People.css';

const People = ({content}) => (
  <Main className="people">
    {content.hero && <RoutingHero className="people__hero" hero={content.hero} />}

    <Lin3rs lin3rs={content.people} />
  </Main>
);

export default React.memo(People);
