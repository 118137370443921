import React from 'react';
import ReactModal from 'react-modal';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Iconography, {ICON} from '../../atoms/Iconography/Iconography';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import styles from './Modal.css';

ReactModal.setAppElement('#root');

const Modal = ({
  children,
  className,
  contentClassName,
  contentLabel,
  closeModal,
  fullHeight,
  isOpen,
  onCloseClick,
  withCloseIcon,
}) => (
  <ReactModal
    bodyOpenClassName={styles.modalOpened}
    className={classNames(styles.modal, {[styles.modalFull]: fullHeight}, className)}
    contentLabel={contentLabel}
    isOpen={isOpen}
    onRequestClose={closeModal}
    shouldFocusAfterRender={false}
  >
    <div className={`grid ${styles.modalBody}`}>
      <div className={`${styles.modalContent} ${contentClassName}`}>
        <div className="modal-top">
          <div className="modal-top__title">
            <Typography className="" variant={VARIANT.BIG_BODY}>
              <FormattedMessage id="popup.title" />
            </Typography>
            <Typography className="" variant={VARIANT.BODY}>
              <FormattedMessage id="popup.subtitle" />
            </Typography>
          </div>
          {withCloseIcon && <Iconography className={styles.modalClose} icon={ICON.CLOSE} onClick={onCloseClick} />}
        </div>
        <div className={styles.modalContentChild}>{children}</div>
      </div>
    </div>
  </ReactModal>
);

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeModal: PropTypes.func,
  contentLabel: PropTypes.string,
  fullHeight: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCloseClick: PropTypes.func,
  withCloseIcon: PropTypes.bool,
};

Modal.defaultProps = {
  withCloseIcon: true,
};

export default Modal;
