import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';

import getPageScrollTop from './../_util/getPageScrollTop';

const THROTTLE_WAIT = 150;
const OFFSET_THRESHOLD = 0;
const SCROLL_DIRECTION_UP = 'UP';
const SCROLL_DIRECTION_DOWN = 'DOWN';

function getScrollDirection(scrollTop, offsetThreshold, lastScrollTop, currentScrollDirection) {
  if (scrollTop <= offsetThreshold) {
    return null;
  } else if (scrollTop < lastScrollTop) {
    return SCROLL_DIRECTION_UP;
  } else if (scrollTop > lastScrollTop) {
    return SCROLL_DIRECTION_DOWN;
  }

  return currentScrollDirection;
}

class ScrollDirection extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      scrollDirection: getScrollDirection(getPageScrollTop(), OFFSET_THRESHOLD, 0, null),
      scrolled: false,
    };

    this.lastScrollTop = 0;

    this.boundHandleScroll = throttle(this.handleScroll.bind(this), THROTTLE_WAIT);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.boundHandleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.boundHandleScroll);
  }

  handleScroll() {
    const offsetLimit = this.props.scrolledThreshold;

    window.requestAnimationFrame(() => {
      const scrollDirection = this.state.scrollDirection;
      const scrollTop = getPageScrollTop();
      const nextScrollDirection = getScrollDirection(scrollTop, OFFSET_THRESHOLD, this.lastScrollTop, scrollDirection);

      if (scrollDirection !== nextScrollDirection) {
        this.setState({scrollDirection: nextScrollDirection});
      }

      this.setState({scrolled: scrollTop > offsetLimit});

      this.lastScrollTop = scrollTop;
    });
  }

  render() {
    const {scrollDirection, scrolled} = this.state;

    return this.props.children({scrollDirection, scrolled});
  }
}

ScrollDirection.propTypes = {
  children: PropTypes.func.isRequired,
  scrolledThreshold: PropTypes.number,
};

ScrollDirection.defaultProps = {
  scrolledThreshold: 0,
};

export {SCROLL_DIRECTION_UP, SCROLL_DIRECTION_DOWN};
export default ScrollDirection;
