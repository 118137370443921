import React from 'react';
import PropTypes from 'prop-types';

import InlineButton from './../../atoms/InlineButton/InlineButton';
import Typography from './../../atoms/Typography/Typography';

import Content from './../Content/Content';
import Section from './../Section/Section';

import Column from './../../layouts/Column/Column';
import ContentWithSidebar from './../../layouts/ContentWithSidebar/ContentWithSidebar';
import Grid from './../../layouts/Grid/Grid';

import './ListGrid.css';

const shouldShowCta = cta => cta && cta.text && cta.url;

const ListGrid = ({cta, elements, id, renderContainer, label, text, title}) => (
  <ContentWithSidebar
    className="list-grid"
    id={id ? id : null}
    left={
      label && (
        <Column>
          <Typography>{label}</Typography>
        </Column>
      )
    }
  >
    <Column>
      <Section scrollAnimation={false} title={title}>
        {text && (
          <Content className="list-grid__description" raw tag="div">
            {text}
          </Content>
        )}

        {elements && elements.length > 0 && (
          <Typography className="list-grid__grid-wrapper" tag="div">
            <Grid
              columnsPerBreakpoint={{S: 1, SM: 2, L: 3, XXL: 4}}
              itemClassName="list-grid__item"
              scrollAnimation={false}
            >
              {elements}
            </Grid>
          </Typography>
        )}

        {shouldShowCta(cta) &&
          renderContainer(
            <InlineButton className="list-grid__cta">{cta.text}</InlineButton>,
            {rel: cta.relAttribute, target: cta.target},
            cta.url,
          )}
      </Section>
    </Column>
  </ContentWithSidebar>
);

ListGrid.propTypes = {
  cta: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  elements: PropTypes.arrayOf(PropTypes.string.isRequired),
  id: PropTypes.string,
  label: PropTypes.node,
  renderContainer: PropTypes.func,
  text: PropTypes.node,
  title: PropTypes.node,
};

ListGrid.defaultProps = {
  renderContainer: children => children,
};

export default ListGrid;
