import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import FullRow from './../../layouts/FullRow/FullRow';

import './ContentWithSidebar.css';

const ContentWithSidebar = ({children, className, left, right, ...rest}) => (
  <FullRow className={classNames('content-with-sidebar', className)} {...rest}>
    <div className="content-with-sidebar__left">{left}</div>
    <div className="content-with-sidebar__content">{children}</div>
    <div className="content-with-sidebar__right">{right}</div>
  </FullRow>
);

ContentWithSidebar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node,
};

export default React.memo(ContentWithSidebar);
