import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import responsiveImagePropType from './../../_propTypes/responsiveImagePropType';
import overrideHeight from './../../_util/overrideHeight';

import Picture from './../../atoms/Picture/Picture';
import Iconography, {ICON} from './../../atoms/Iconography/Iconography';
import Video from './../../atoms/Video/Video';
import InlineButton from '../../atoms/InlineButton/InlineButton';
import {VARIANT} from '../../atoms/Typography/Typography';

import Content from './../../components/Content/Content';
import HeroLabel from '../../components/HeroLabel/HeroLabel';

import Column from './../../layouts/Column/Column';
import ContentWithSidebar from './../../layouts/ContentWithSidebar/ContentWithSidebar';

import './Hero.css';

const videoPlayerProps = {loop: true, muted: true, playing: true, playsinline: true};

const HeroImage = ({image}) => (
  <Picture
    alt={image.alt}
    className="hero__picture"
    cover
    imageClassName="hero__picture-image"
    pictureClassName="hero__picture-picture"
    src={image.src.large || image.src.original}
    srcSet={[
      {
        src: image.src.medium,
        maxWidth: 639,
      },
    ]}
  />
);

const Hero = ({className, cta, heroWithLogo, image, renderCTAContainer, label, title, videoUrl}) => {
  const [activeMedia, setActiveMedia] = useState(false);
  const toggleActiveMedia = () => setActiveMedia(!activeMedia);
  const withMedia = image || videoUrl;
  const heightStyle = withMedia ? overrideHeight() : {};

  useEffect(() => {
    var increment = 0;

    function move() {
      increment += 0.5;
      const root = document.documentElement;
      root.style.setProperty('--hero-ty', `-${increment}%`);
      root.style.setProperty('--hero-rt', `${increment * 3.6}deg`);

      if (increment < 200) {
        requestAnimationFrame(move);
      }
    }

    if (window.innerWidth > 768) {
      requestAnimationFrame(move);
    }
  });

  return (
    <div
      className={classNames('hero', {'hero--with-media': withMedia, 'hero--active': activeMedia}, className)}
      style={heightStyle}
    >
      <ContentWithSidebar left={label && <HeroLabel label={label} />} scrollAnimation={false}>
        <Column className="hero__content" scrollAnimation={false}>
          {heroWithLogo && (
            <Link className="hero__logo" to="/">
              <Iconography className="hero__logo-icon" icon={ICON.LIN3S} />
            </Link>
          )}
          <Content raw tag="h1" variant={VARIANT.H1}>
            {title}
          </Content>

          {cta.text && !cta.url && <InlineButton onClick={toggleActiveMedia}>{cta.text}</InlineButton>}
          {cta.text && cta.url && renderCTAContainer(<InlineButton>{cta.text}</InlineButton>, cta.url)}
        </Column>
      </ContentWithSidebar>

      {withMedia && (
        <div className="hero__media">
          {image ? (
            <HeroImage image={image} />
          ) : (
            <Video
              className="hero__video"
              controls={false}
              playerProps={videoPlayerProps}
              src={videoUrl}
              withRatio={false}
            />
          )}
          {activeMedia && <Iconography className="hero__media-close" icon={ICON.CLOSE} onClick={toggleActiveMedia} />}
        </div>
      )}
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string,
  cta: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  heroWithLogo: PropTypes.bool.isRequired,
  image: responsiveImagePropType,
  label: PropTypes.node,
  renderCTAContainer: PropTypes.func,
  title: PropTypes.node.isRequired,
  videoUrl: PropTypes.string,
};

Hero.defaultProps = {
  cta: {},
  heroWithLogo: false,
  renderButtonContainer: children => children,
};

export default React.memo(Hero);
