export const isPhone = new RegExp(/^((\+\d{2})?)(\(?\d{3}\)?)(-?\d{3})(-?\d{3,4})$/);
const isMail = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+(\?.*)?$/);

export const isURLPhoneOrMail = url => isPhone.test(url) || isMail.test(url);

const replaceURLForPhoneOrMail = url => {
  if (isPhone.test(url)) {
    return `tel:${url}`;
  }

  if (isMail.test(url)) {
    return `mailto:${url}`;
  }

  return url;
};

export default replaceURLForPhoneOrMail;
