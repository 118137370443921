import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '../Typography/Typography';

import './InlineButton.css';

const InlineButton = ({children, className, inverted, tag, withHover, hideUnderline, ...rest}) => (
  <Typography
    className={classNames(
      'inline-button',
      {
        'inline-button--inverted': inverted,
        'inline-button--with-hover': withHover,
        'inline-button--line-on-hover': hideUnderline,
      },
      className,
    )}
    tag={tag}
    {...rest}
  >
    {children}
  </Typography>
);

InlineButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hideUnderline: PropTypes.bool,
  inverted: PropTypes.bool,
  tag: PropTypes.string,
  withHover: PropTypes.any,
};

InlineButton.defaultProps = {
  hideUnderline: false,
  inverted: false,
  withHover: true,
};

export default InlineButton;
