import React from 'react';

import CmsLink from './../../components/CmsLink/CmsLink';

import Hero from './../../compositions/Hero/Hero';

const RoutingHero = ({hero, ...rest}) =>
  hero ? (
    <Hero renderCTAContainer={(children, url) => <CmsLink slug={url}>{children}</CmsLink>} {...hero} {...rest} />
  ) : null;

export default RoutingHero;
