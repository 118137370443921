import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';

import withSize from './../../../_packages/resize/ui/withSize';

import BusinessBranch from './../../components/BusinessBranch/BusinessBranch';

import FullRow from './../../layouts/FullRow/FullRow';
import Grid from './../../layouts/Grid/Grid';

import './BusinessBranches.css';

const BusinessBranches = ({businessBranches, id}) => (
  <Grid
    className="business-branches column"
    columnsPerBreakpoint={{S: 1, SM: 2, XL: 4}}
    id={id ? id : null}
    itemClassName="business-branches__item"
    tag={FullRow}
  >
    {businessBranches.map((businessBranch, index) => (
      <BusinessBranch key={index} {...businessBranch} />
    ))}
  </Grid>
);

BusinessBranches.propTypes = {
  businessBranches: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.string,
};

export default compose(
  withSize,
  React.memo,
)(BusinessBranches);
