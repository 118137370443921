import PropTypes from 'prop-types';
import React from 'react';

import responsiveImagePropType from './../../_propTypes/responsiveImagePropType';

import Column from '../../layouts/Column/Column';
import Row from '../../layouts/Row/Row';

import Picture from './../../atoms/Picture/Picture';
import Typography, {VARIANT} from '../../atoms/Typography/Typography';

import './LogoRepeater.css';

const LogoRepeater = ({elements, id, title}) => (
  <section className="logo-repeater" id={id}>
    <Row>
      <Column>
        <Typography className="section-title logo-repeater__title" variant={VARIANT.H2}>
          {title}
        </Typography>
      </Column>
    </Row>
    <Row className="logo-repeater__grid">
      {elements.map((logo, index) => (
        <div className="logo-repeater__grid-item">
          <Picture
            alt={logo.alt}
            key={index}
            pictureClassName="logo-repeater__picture"
            src={logo.src.medium || logo.src.original}
          />
        </div>
      ))}
    </Row>
  </section>
);

LogoRepeater.propTypes = {
  elements: PropTypes.arrayOf(responsiveImagePropType).isRequired,
  id: PropTypes.string,
  title: PropTypes.node.isRequired,
};

export default LogoRepeater;
