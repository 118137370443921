import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';

import Modal from '../../layouts/Modal/Modal';
import ContactForm from '../../pages/BackendPage/Contact/ContactForm';

import './NewsletterPopup.css';

const COOKIE = 'newsletter-modal-seen';

const NewsletterPopup = ({portalId, formId}) => {
  const [modalSeen, setModalSeen] = useState(cookie.load(COOKIE) === undefined ? false : cookie.load(COOKIE));
  const newsletterModalHasBeenSeen = () => {
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);

    cookie.save(COOKIE, true, {
      path: '/',
      expires: expires,
      domain: process.env.REACT_APP_HOST_DOMAIN,
      secure: true,
    });

    setModalSeen(true);
  };

  useEffect(() => {
    if (!document.getElementById('newsletter-popup-contact-form')) {
      return;
    }

    document
      .getElementById('newsletter-popup-contact-form')
      .querySelector('input[type="submit"]')
      .addEventListener('click', newsletterModalHasBeenSeen());
  }, []);

  return (
    <Modal className="newsletter-popup" isOpen={!modalSeen} onCloseClick={newsletterModalHasBeenSeen}>
      <ContactForm
        className="newsletter-popup__contact-form"
        formInfo={{formId: formId, portalId: portalId}}
        id="newsletter-popup-contact-form"
        invertedColor
        visibleFromStart
      />
    </Modal>
  );
};

NewsletterPopup.propTypes = {
  formId: PropTypes.string,
  portalId: PropTypes.string,
};
export default NewsletterPopup;
