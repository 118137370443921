import React from 'react';

import RoutingHero from './../../_bridges/RoutingHero/RoutingHero';

import Main from './../../layouts/Main/Main';
import GenericItems from '../../layouts/GenericItems/GenericItems';

import HomepageElement from './HomepageElement/HomepageElement';

import './Homepage.css';

const Elements = ({elements}) =>
  elements.length > 0 ? (
    <>
      {elements.map((element, index) => (
        <HomepageElement element={element} key={index} />
      ))}
    </>
  ) : null;

const Homepage = ({content: home}) => (
  <Main className="homepage">
    <RoutingHero hero={home.hero} />

    {home.items && <GenericItems items={home.items} />}

    <Elements elements={home.content.elements} />
  </Main>
);

export default React.memo(Homepage);
