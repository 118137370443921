import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import withSize from './../../../_packages/resize/ui/withSize';
import BREAKPOINTS from './../../_config/breakpoints';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Iconography, {ICON} from './../../atoms/Iconography/Iconography';

import './BusinessBranch.css';

const TYPES = {
  TYPE1: ICON.PARALLEL_LINES,
  TYPE2: ICON.PARALLEL_LINES2,
  TYPE3: ICON.PARALLEL_LINES3,
  TYPE4: ICON.PARALLEL_LINES4,
};

const BusinessBranch = ({className, elements, title, type, windowWidth}) => (
  <div className={classNames('business-branch', className)}>
    <div className="business-branch__title-wrapper">
      <Iconography
        className="business-branch__title-background"
        icon={windowWidth >= BREAKPOINTS.L ? type : `${type}_MOBILE`}
      />
      <Typography className="business-branch__title" variant={VARIANT.H2}>
        {title}
      </Typography>
    </div>

    <ul>
      {elements.map((element, index) => (
        <Typography className="business-branch__element" key={index} tag="li">
          {element}
        </Typography>
      ))}
    </ul>
  </div>
);

BusinessBranch.propTypes = {
  className: PropTypes.string,
  elements: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
};

export {TYPES};
export default withSize(BusinessBranch);
