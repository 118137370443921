import {flush, pushPageLoadDataLayer} from 'lin3s-react-analytics';

const pushPageView = () => {
  pushPageLoadDataLayer();
  flush();
};

export default {
  pushPageView,
};
