import React from 'react';

import Typography, {VARIANT} from '../../atoms/Typography/Typography';
import Iconography, {ICON} from '../../atoms/Iconography/Iconography';

import Column from '../../layouts/Column/Column';
import Row from '../../layouts/Row/Row';
import Grid from '../../layouts/Grid/Grid';

import './LocationComponent.css';

const LocationComponent = ({title, places, id}) => (
  <section className="location-component" id={id}>
    <Row className="location-component__title">
      <Column>
        <Typography variant={VARIANT.H2}>{title}</Typography>
      </Column>
    </Row>
    <Grid columnsPerBreakpoint={{S: 1, SM: 2, L: 3, XXL: 4}} itemClassName="location-component__grid">
      {places.map((item, index) => (
        <div className="location-component__places-item" key={`donation-place--${index}`}>
          <Iconography className="location-component__place-icon" icon={ICON.SMALL_DROP} />
          <div className="location-component__place-item-content">
            <Typography className="location-component__place-title">{item.title}</Typography>
            <Typography className="location-component__place-place">{item.location}</Typography>
            <Typography className="location-component__place-schedule">{item.schedule}</Typography>
          </div>
        </div>
      ))}
    </Grid>
  </section>
);

export default LocationComponent;
