import React, {useCallback} from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {compose} from 'redux';

import withSize from './../../../_packages/resize/ui/withSize';
import ScrollDirection, {SCROLL_DIRECTION_DOWN} from './../../_behaviours/ScrollDirection';
import getScrolledThreshold from './../../_util/getScrolledThreshold';
import {closeMenu as closeMenuAction} from './../../../store/Ui/UiActions';

import FullRow from './../FullRow/FullRow';
import Column from './../Column/Column';

import HeaderMenuToggle from './HeaderMenuToggle/HeaderMenuToggle';
import HeaderMenu from './HeaderMenu/HeaderMenu';

import './Header.css';

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
const HeaderContent = ({closeMenu, hidden, inverted, opened}) => {
  const conditionalCloseMenu = useCallback(
    e => {
      if (!opened) {
        return;
      }
      const clickedOnLink =
        e.target.closest &&
        (Boolean(e.target.closest('.header a')) || Boolean(e.target.closest('.language-selector__option-current')));
      !clickedOnLink && closeMenu();
    },
    [opened, closeMenu],
  );

  return (
    <header
      className={classNames('header', {
        'header--hidden': !inverted && hidden,
        'header--inverted': inverted,
        'header--opened': opened,
      })}
      onClick={conditionalCloseMenu}
    >
      <FullRow className="header__nav" scrollAnimation={false}>
        <Column>
          <HeaderMenuToggle />
        </Column>
      </FullRow>

      {!hidden && <HeaderMenu />}
    </header>
  );
};
/* eslint-enable jsx-a11y/no-static-element-interactions */
/* eslint-enable jsx-a11y/click-events-have-key-events */

const Header = ({opened, windowWidth, ...rest}) => (
  <ScrollDirection scrolledThreshold={getScrolledThreshold(windowWidth)}>
    {({scrollDirection, scrolled}) => {
      const hidden = !opened && scrolled && scrollDirection === SCROLL_DIRECTION_DOWN;

      return <HeaderContent {...rest} hidden={hidden} opened={opened} />;
    }}
  </ScrollDirection>
);

const mapStateToProps = state => ({
  opened: state.ui.menuOpened,
});

const mapDispatchToProps = {closeMenu: closeMenuAction};

export default compose(
  React.memo,
  withSize,
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Header);
