export const CMS_HOMEPAGE_SLUG = '/home';

export const TEMPLATE = {
  CASE_STUDIES: 'caseStudies',
  CASE_STUDY: 'caseStudy',
  JOB_POSITION: 'jobPosition',
  PAGE: 'page',
  PEOPLE: 'people',
  RESOURCES: 'resources',
  RESOURCE: 'resource',
};

export const PAGE_TEMPLATE = {
  HOME: 'index',
  GENERIC: 'generic',
  LANDING: 'landing',
  WHITE: 'white',
  DEFAULT: 'default',
};
