import React from 'react';
import {FormattedNavLink} from '@foes/react-i18n-routing';

import {CASE_STUDY} from '../../../routing/caseStudies/routes';

import caseStudyPreviewPropType from './../../_propTypes/caseStudyPreviewPropType';
import CaseStudyPreview from './../../components/CaseStudyPreview/CaseStudyPreview';

const RoutingCaseStudyPreview = ({caseStudy, ...rest}) => (
  <CaseStudyPreview
    caseStudy={caseStudy}
    renderContainer={children => (
      <FormattedNavLink name={CASE_STUDY} params={{slug: caseStudy.slug}}>
        {children}
      </FormattedNavLink>
    )}
    {...rest}
  />
);

RoutingCaseStudyPreview.propTypes = {
  caseStudy: caseStudyPreviewPropType.isRequired,
};

export default RoutingCaseStudyPreview;
