import React, {useState, useEffect, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {withRouter} from 'react-router';

// import {useLocation} from 'react-router-dom';
import isEqual from 'lodash.isequal';
import uniqWith from 'lodash.uniqwith';

import RoutingCaseStudyPreview from './../../_bridges/RoutingCaseStudyPreview/RoutingCaseStudyPreview';
import RoutingHero from './../../_bridges/RoutingHero/RoutingHero';

import Column from '../../layouts/Column/Column';
import ContentWithSidebar from '../../layouts/ContentWithSidebar/ContentWithSidebar';
import FullRow from './../../layouts/FullRow/FullRow';
import GenericItems from './../../layouts/GenericItems/GenericItems';
import Grid from './../../layouts/Grid/Grid';
import Main from './../../layouts/Main/Main';
import Row from '../../layouts/Row/Row';

import InlineTag from '../../atoms/InlineTag/InlineTag';

import HeroLabel from '../../components/HeroLabel/HeroLabel';
import ResourcePreviewList from '../../compositions/ResourcePreviewList/ResourcePreviewList';

import './CaseStudies.css';

const flatSectors = caseStudies =>
  uniqWith(caseStudies.reduce((carry, caseStudy) => carry.concat(caseStudy.sectors), []), isEqual).sort(
    (sector, nextSector) => (sector.order > nextSector.order ? 1 : -1),
  );

const TagButton = ({tag, active}) => (
  <a href={`?sector=${tag.slug}`}>
    <InlineTag inverted={active}>{tag.name}</InlineTag>
  </a>
);

const CaseStudies = ({content, location}) => {
  const search = location.search;

  const sectors = flatSectors(content.caseStudies);
  const [activeSector, setSector] = useState(sectors.length > 0 ? sectors[0].slug : null);

  const updateActiveSector = useCallback(sector => {
    setSector(activeSector !== null && activeSector.slug === sector ? null : sector);
  });

  const filterCaseStudies = useCallback(caseStudies => {
    if (activeSector === null) {
      return caseStudies;
    }
    // eslint-disable-next-line max-len
    return caseStudies.filter(
      caseStudy => caseStudy.sectors.filter(sector => activeSector === sector.slug).length === 1,
    );
  });

  const getActiveSector = useCallback(() => {
    if (activeSector === null) {
      return null;
    }
    const item = sectors.filter(sector => sector.slug === activeSector)[0];
    if (!item || !item.resources) {
      return null;
    }
    return item.resources;
  });

  useEffect(() => {
    const newSector = new URLSearchParams(search).get('sector');
    if (newSector === null) {
      setSector(sectors.length > 0 ? sectors[0].slug : null);
    } else {
      updateActiveSector(newSector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Main className="case-studies">
      {content.hero && <RoutingHero className="case-studies__hero" hero={content.hero} />}
      <ContentWithSidebar left={<HeroLabel label={<FormattedMessage id="tags.showing" />} />}>
        <Row>
          <Column>
            <div style={{display: 'inline-flex', flexDirection: 'row', flexWrap: 'wrap', gap: '12px'}}>
              {sectors &&
                sectors
                  .sort((a, b) => parseInt(a.order, 10) - parseInt(b.order, 10))
                  .map((sector, key) => (
                    <TagButton
                      active={sector.slug === activeSector}
                      key={key}
                      // onClick={() => updateActiveSector(sector)}
                      tag={sector}
                    />
                  ))}
            </div>
          </Column>
        </Row>
      </ContentWithSidebar>

      {content.caseStudies && content.caseStudies.length > 0 && (
        <Grid
          className="row--top-80 case-studies__list"
          columnsPerBreakpoint={{S: 1, SM: 2}}
          itemClassName="case-studies__list-item"
          tag={FullRow}
        >
          {filterCaseStudies(content.caseStudies).map((caseStudy, index) => (
            <RoutingCaseStudyPreview caseStudy={caseStudy} key={index} />
          ))}
        </Grid>
      )}

      {activeSector && getActiveSector().length > 0 && (
        <ResourcePreviewList allowFirst={true} resources={getActiveSector()} />
      )}

      {content.items && <GenericItems className="case-studies__generic-items" items={content.items} />}
    </Main>
  );
};

export default React.memo(withRouter(CaseStudies));
