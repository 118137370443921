export const CASE_STUDIES = 'case_studies';
export const CASE_STUDY = 'case_study';

export default {
  [CASE_STUDY]: {
    es: '/proyectos/:slug',
    en: '/projects/:slug',
    fr: '/projets/:slug',
  },
  [CASE_STUDIES]: {
    es: '/proyectos',
    en: '/projects',
    fr: '/projets',
  },
};
