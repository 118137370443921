import React, {useEffect, useState} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import get from 'lodash.get';

import getCounterAction from '../../../store/Counter/CounterActions';

import BREAKPOINTS from './../../_config/breakpoints';

import withSize from './../../../_packages/resize/ui/withSize';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Iconography, {ICON, SIZE} from './../../atoms/Iconography/Iconography';

import Row from './../../layouts/Row/Row';
import FullRow from './../../layouts/FullRow/FullRow';
import Column from './../../layouts/Column/Column';

import './CounterComponent.css';

const CounterComponent = ({people, counter, windowWidth, contactform, getCounter, elementNumber}) => {
  const [timeReached, setTimeReached] = useState(false);
  const [timeToShow, setTimeToShow] = useState('');

  const dropNumber = contactform ? elementNumber : Math.trunc(people.number * 0.45 * 10);

  var dropsToShow = [];
  for (let i = 0; i < dropNumber; i++) {
    dropsToShow.push(
      <div className="counter-component__drop-list-item" key={`drop-icon--${i}`}>
        <Iconography className="counter-component__drop-list-item-icon" icon={ICON.BIG_DROP} />
        {!contactform && (
          <Iconography className="counter-component__drop-list-item-tooltip" icon={ICON.TOOLTIP} size={SIZE.BIG} />
        )}
      </div>,
    );
  }

  let interval;

  const endDate = new Date(counter.date);

  const timeBetweenDates = () => {
    const now = new Date();
    const difference = endDate.getTime() - now.getTime();

    if (difference < 0) {
      clearInterval(interval);
      setTimeReached(true);
      return;
    }

    let seconds = Math.floor(difference / 1000),
      minutes = Math.floor(seconds / 60),
      hours = Math.floor(minutes / 60);

    const days = Math.floor(hours / 24);
    hours %= 24;
    minutes %= 60;
    seconds %= 60;

    setTimeToShow({
      days: days.toLocaleString('es', {minimumIntegerDigits: 2}),
      hours: hours.toLocaleString('es', {minimumIntegerDigits: 2}),
      minutes: minutes.toLocaleString('es', {minimumIntegerDigits: 2}),
      seconds: seconds.toLocaleString('es', {minimumIntegerDigits: 2}),
    });
  };

  useEffect(() => {
    if (!contactform) {
      if (endDate.getTime() - new Date().getTime() > 0) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        interval = setInterval(() => {
          timeBetweenDates();
        }, 1000);
      } else {
        setTimeReached(true);
      }
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    async function callGetCounter() {
      await getCounter({slug: contactform});
    }

    callGetCounter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const containerHeigthRatio = windowWidth < BREAKPOINTS.XL ? 1.3 : 0.3;

    // Container data
    const container = document.querySelector('.counter-component__drop-list');
    const containerWidth = container.offsetWidth;
    const containerHeigth = containerWidth * containerHeigthRatio;
    const containerArea = containerWidth * containerHeigth;

    // Icon data
    const iconWidth = 384;
    const iconHeigth = 589;

    // Final area
    const areaPerItem = contactform ? containerArea / elementNumber : containerArea / dropNumber;
    const ratioWidth = Math.sqrt(areaPerItem * (iconWidth / iconHeigth));

    const itemsPerRow = Math.ceil(containerWidth / ratioWidth);

    const finalWidth = containerWidth / itemsPerRow;

    document.documentElement.style.setProperty('--width', `${finalWidth}px`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, dropsToShow]);

  if (contactform) {
    return (
      <section className="counter-component counter-component-two-columns">
        <Row className="counter-component__row-top">
          <Column className="counter-component__colum-people">
            <Typography className="counter-component__column-pretitle" variant={VARIANT.SMALL_BODY}>
              {people.pretext}
            </Typography>
            <Typography className="counter-component__column-content" variant={VARIANT.BIG_H1}>
              {`${Math.min(2000, elementNumber)}`}
            </Typography>
            <Typography className="counter-component__column-postitle" variant={VARIANT.H3}>
              {contactform ? people.message : timeReached ? people.message : people.posttext}
            </Typography>
          </Column>
          <Column className="counter-component__colum-counter">
            <Typography className="counter-component__column-pretitle" variant={VARIANT.SMALL_BODY}>
              {counter.pretext}
            </Typography>
            <Typography className="counter-component__column-content" variant={VARIANT.BIG_H1}>
              {`${Math.min(2000, elementNumber)}€`}
            </Typography>
            <Typography className="counter-component__column-postitle" variant={VARIANT.H3}>
              {counter.message}
            </Typography>
          </Column>
        </Row>
        <FullRow className="counter-component__drop-list">{dropsToShow.map(item => item)}</FullRow>
      </section>
    );
  }

  return (
    <section className="counter-component">
      <Row className="counter-component__row-top">
        <Column className="counter-component__colum-people">
          {!timeReached && (
            <Typography className="counter-component__column-pretitle" variant={VARIANT.SMALL_BODY}>
              {people.pretext}
            </Typography>
          )}
          <Typography className="counter-component__column-content" variant={VARIANT.BIG_H1}>
            {people.number * 0.45}
          </Typography>
          <Typography className="counter-component__column-postitle" variant={VARIANT.H3}>
            {timeReached ? people.message : people.posttext}
          </Typography>
        </Column>
        <Column className="counter-component__colum-counter">
          {!timeReached && (
            <>
              <Typography className="counter-component__column-pretitle" variant={VARIANT.SMALL_BODY}>
                {counter.pretext}
              </Typography>
              <div className="counter-component__column-content-items">
                <div className="counter-component__column-content-item">
                  <Typography className="counter-component__column-content" variant={VARIANT.BIG_H1}>
                    {timeToShow.days}
                  </Typography>
                  <Typography className="counter-component__column-postitle" variant={VARIANT.H3}>
                    <FormattedMessage id="counter.component.days" />
                  </Typography>
                </div>
                <div className="counter-component__column-content-item">
                  <Typography className="counter-component__column-content" variant={VARIANT.BIG_H1}>
                    {timeToShow.hours}
                  </Typography>
                  <Typography className="counter-component__column-postitle" variant={VARIANT.H3}>
                    <FormattedMessage id="counter.component.hours" />
                  </Typography>
                </div>
                <div className="counter-component__column-content-item">
                  <Typography className="counter-component__column-content" variant={VARIANT.BIG_H1}>
                    {timeToShow.minutes}
                  </Typography>
                  <Typography className="counter-component__column-postitle" variant={VARIANT.H3}>
                    <FormattedMessage id="counter.component.minutes" />
                  </Typography>
                </div>
                <div className="counter-component__column-content-item">
                  <Typography className="counter-component__column-content" variant={VARIANT.BIG_H1}>
                    {timeToShow.seconds}
                  </Typography>
                  <Typography className="counter-component__column-postitle" variant={VARIANT.H3}>
                    <FormattedMessage id="counter.component.seconds" />
                  </Typography>
                </div>
              </div>
            </>
          )}
          {timeReached && (
            <>
              <Typography className="counter-component__column-content" variant={VARIANT.BIG_H1}>
                {people.number * 3}
              </Typography>
              <Typography className="counter-component__column-postitle" variant={VARIANT.H3}>
                {counter.message}
              </Typography>
            </>
          )}
        </Column>
      </Row>
      <FullRow className="counter-component__drop-list">{dropsToShow.map(item => item)}</FullRow>
    </section>
  );
};

const mapDispatchToProps = dispatch => ({
  getCounter: bindActionCreators(getCounterAction, dispatch),
});

const mapStateToProps = state => ({
  elementNumber: get(state, 'counter.data'),
});

export default compose(
  React.memo,
  withSize,
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CounterComponent);
