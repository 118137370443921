export const RESOURCES = 'resources';
export const RESOURCE = 'resource';

export default {
  [RESOURCE]: {
    es: '/recursos/:slug',
    en: '/recursos/:slug',
    fr: '/recursos/:slug',
  },
  [RESOURCES]: {
    es: '/recursos',
    en: '/resources',
    fr: '/ressources',
  },
};
