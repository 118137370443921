import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography, {VARIANT} from './../Typography/Typography';

import './Button.css';

const Button = ({children, className, tag, type, ...rest}) => (
  <Typography
    className={classNames('button', 'button--center', className)}
    tag={tag}
    type={type}
    variant={VARIANT.BODY}
    {...rest}
  >
    <span className="button__content">{children}</span>
  </Typography>
);

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  tag: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
};

Button.defaultProps = {
  tag: 'button',
};

export default Button;
