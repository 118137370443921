import React from 'react';
import PropTypes from 'prop-types';

const ExternalLink = ({href, children, target, ...rest}) => (
  <a href={href} rel="noopener noreferrer nofollow" target={target} {...rest}>
    {children}
  </a>
);

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
};

ExternalLink.defaultProps = {
  target: '_blank',
};

export default ExternalLink;
