import React from 'react';

import RoutingHero from './../../_bridges/RoutingHero/RoutingHero';

import GenericItems from './../../layouts/GenericItems/GenericItems';
import Main from './../../layouts/Main/Main';
import FullRow from './../../layouts/FullRow/FullRow';
import Row from './../../layouts/Row/Row';
import ContactForm from './../../pages/BackendPage/Contact/ContactForm';

import './JobPosition.css';

const JobPosition = ({content: jobPosition}) => {
  const hubspot = 'jobPositionHubspot' in jobPosition.settings ? jobPosition.settings.jobPositionHubspot : null;
  return (
    <Main className="job-position">
      {jobPosition.hero && <RoutingHero hero={jobPosition.hero} />}

      {jobPosition.blocks.items && (
        <GenericItems className="job-position__generic-items" items={jobPosition.blocks.items} />
      )}

      {hubspot && hubspot.portalId && hubspot.formId && (
        <FullRow className="white-list__form job-position__form" scrollAnimation={false}>
          <Row className="white-list__column">
            <ContactForm formInfo={hubspot} invertedColor={true} visibleFromStart={true} />
          </Row>
        </FullRow>
      )}
    </Main>
  );
};

export default React.memo(JobPosition);
