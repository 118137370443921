import React, {useEffect, useRef} from 'react';

import Row from '../../layouts/Row/Row';
import Column from '../../layouts/Column/Column';

import './Teamtailor.css';

const TeamtailorForm = () => {
  const teamtailor = useRef();
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://scripts.teamtailor-cdn.com/widgets/production/jobs.js';
    script.charset = 'utf-8';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (teamtailor.current) {
      const observer = new MutationObserver(() => {
        const teamtailorJobs = teamtailor.current.querySelectorAll('.teamtailor-jobs__job');
        teamtailorJobs.forEach(element => {
          element.addEventListener('click', event => updateClick(event));
        });
      });

      observer.observe(teamtailor.current, {
        subtree: true,
        attributes: true,
        childList: true,
      });
    }

    const updateClick = jobs => {
      const link = jobs.target.querySelector('a.teamtailor-jobs__job-title');
      if (link) {
        link.click();
      }
    };
  }, []);

  return (
    <Row>
      <Column className="teamtailor__form">
        <div
          className="teamtailor-jobs-widget"
          data-teamtailor-api-key="2Kh5wAoUxwC4zxUElRH-vXnCyCeKjGL-9RkXfqMc"
          data-teamtailor-department-select="true"
          data-teamtailor-limit="12"
          data-teamtailor-pagination="true"
          data-teamtailor-popup="true"
          data-teamtailor-remote-status-select="true"
          ref={teamtailor}
        ></div>
      </Column>
    </Row>
  );
};

export default TeamtailorForm;
