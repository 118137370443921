import React from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Main = ({children, className, ...rest}) => {
  const isIE = useSelector(state => state.ui.isIE);
  const MainTag = isIE ? 'div' : 'main';

  return (
    <MainTag className={classNames('main', className)} role="main" {...rest}>
      {children}
    </MainTag>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Main;
