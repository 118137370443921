import axios from 'axios';
import {cacheAdapterEnhancer} from 'axios-extensions';

import isSSR from './../../config/isSSR';

const cacheAdapter = isSSR() ? {} : {adapter: cacheAdapterEnhancer(axios.defaults.adapter)};

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}`,
  ...cacheAdapter,
});

// Added response interceptor to catch error
instance.interceptors.response.use(response => Promise.resolve(response), error => Promise.reject(error));

const cancelableRequest = (axiosInstance, method) => (url, params = {}) => {
  const source = axios.CancelToken.source();
  Object.assign(params, {cancelToken: source.token});

  const req = axiosInstance[method](url, params);
  req.cancel = payload => {
    source.cancel(payload);
  };

  return req;
};

const cancelableGet = cancelableRequest(instance, 'get');

export {cancelableGet};

export default instance;
