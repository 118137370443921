import React, {useRef} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {useChain, useSpring, useTransition, animated} from 'react-spring';
import chunk from 'lodash.chunk';
import {injectIntl} from 'react-intl';

import BREAKPOINTS from './../../../_config/breakpoints';
import withSize from './../../../../_packages/resize/ui/withSize';
import overrideHeight from './../../../_util/overrideHeight';
import {getFooterMenu, getHeaderMenu, getSettings} from '../../../../store/Api/ApiReducer';

import LanguageSelector from './../LanguageSelector/LanguageSelector';

import Typography, {VARIANT} from '../../../atoms/Typography/Typography';
import InlineButton from './../../../atoms/InlineButton/InlineButton';

import CmsLink from './../../../components/CmsLink/CmsLink';
import SocialLinks from './../../../components/SocialLinks/SocialLinks';

import Column from './../../Column/Column';
import FullRow from './../../FullRow/FullRow';

import './HeaderMenu.css';

const HeaderMenuItems = ({menuItems, windowWidth}) => {
  const menuItemsByGroups = menuItems && chunk(menuItems, 3);
  return (
    <div className="header-menu__items">
      {menuItemsByGroups.map((items, index) => (
        <FullRow className="header-menu__item-wrapper" key={index} scrollAnimation={false}>
          {items.map(menuItem => (
            <Column className="header-menu__item" key={menuItem.slug}>
              <CmsLink slug={menuItem.slug}>
                <InlineButton
                  hideUnderline={true}
                  variant={windowWidth < BREAKPOINTS.M ? VARIANT.H2 : VARIANT.H1}
                  withHover={true}
                >
                  {menuItem.title}
                </InlineButton>
              </CmsLink>
            </Column>
          ))}
        </FullRow>
      ))}
    </div>
  );
};

const HeaderMenu = ({footerMenu, headerMenu, intl, opened, settings, windowWidth}) => {
  const menuItems = headerMenu && headerMenu.items;
  const heightStyle = overrideHeight({slotHeight: 290});

  const transitionRef = useRef();
  const transitions = useTransition(opened, null, {
    config: {duration: 300},
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
    ref: transitionRef,
  });

  const springRef = useRef();
  const springProps = useSpring({
    config: {duration: 200},
    from: {opacity: 0},
    to: {opacity: opened ? 1 : 0},
    ref: springRef,
  });

  useChain(opened ? [transitionRef, springRef] : [springRef, transitionRef], [0, opened ? 0.2 : 0.3]);

  return (
    footerMenu &&
    menuItems &&
    settings &&
    transitions.map(
      ({item, key, props}) =>
        item && (
          <animated.div key={key} style={props}>
            <FullRow className="header-menu__top-wrapper" scrollAnimation={false}>
              <Column>
                <CmsLink slug={'/'}>
                  <InlineButton>{intl.formatMessage({id: 'header.homepage'})}</InlineButton>
                </CmsLink>
              </Column>
              <Column>
                <LanguageSelector />
              </Column>
            </FullRow>

            <div className="header-menu" style={heightStyle}>
              <animated.div style={springProps}>
                <HeaderMenuItems menuItems={menuItems} windowWidth={windowWidth} />
              </animated.div>
            </div>
            <FullRow className="header-menu__footer-wrapper" scrollAnimation={false}>
              {settings.headerCta && (
                <Column>
                  <Typography className="header-menu__contact-title" variant={VARIANT.H3}>
                    {settings.headerCta.title}
                  </Typography>
                  <CmsLink slug={settings.headerCta.ctaUrl}>
                    <InlineButton className="header-menu__cta-link">{settings.headerCta.ctaText}</InlineButton>
                  </CmsLink>
                </Column>
              )}
              {footerMenu.socialLinks && (
                <Column className="header-menu__social">
                  <Typography className="header-menu__social-title" variant={VARIANT.H3}>
                    {intl.formatMessage({id: 'header.follow_us'})}
                  </Typography>
                  <SocialLinks linkClassName="header-menu__social-link" links={footerMenu.socialLinks} />
                </Column>
              )}
            </FullRow>
          </animated.div>
        ),
    )
  );
};

const mapStateToProps = state => ({
  footerMenu: getFooterMenu(state),
  headerMenu: getHeaderMenu(state),
  settings: getSettings(state),
  opened: state.ui.menuOpened,
});

export default compose(
  connect(mapStateToProps),
  injectIntl,
  withSize,
  React.memo,
)(HeaderMenu);
