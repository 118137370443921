import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {getLocale} from '@foes/react-i18n-routing/dist/common/locale';

import {openMenu as openMenuAction, closeMenu as closeMenuAction} from './../../../../store/Ui/UiActions';

import Iconography, {ICON} from './../../../atoms/Iconography/Iconography';
import Typography from './../../../atoms/Typography/Typography';

import './HeaderMenuToggle.css';

const HeaderMenuToggle = ({closeMenu, intl, openMenu, opened}) => {
  const url = getLocale() === 'es' ? '/' : `/${getLocale()}`;
  return (
    <Typography className="header-menu-toggle" onClick={opened ? closeMenu : openMenu}>
      {opened ? (
        <a href={url}>
          <Iconography className="header-menu-toggle__logo" icon={ICON.LIN3S} />
        </a>
      ) : (
        <Iconography
          aria-label={intl.formatMessage({id: 'common.menu'})}
          className="header-menu-toggle__menu"
          icon={ICON.MENU}
        />
      )}
    </Typography>
  );
};

const mapStateToProps = state => ({
  opened: state.ui.menuOpened,
});

const mapDispatchToProps = {closeMenu: closeMenuAction, openMenu: openMenuAction};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl,
  React.memo,
)(HeaderMenuToggle);
