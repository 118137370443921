import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography, {VARIANT} from './../../../atoms/Typography/Typography';

const SectionTitle = ({children, className, tag: Tag, variant, ...rest}) => (
  <Tag className={classNames('section-title', className)} tag="div" variant={variant} {...rest}>
    {children}
  </Tag>
);

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(VARIANT)),
};

SectionTitle.defaultProps = {
  tag: Typography,
  variant: VARIANT.H2,
};

export default React.memo(SectionTitle);
